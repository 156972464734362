import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SignInService } from '../sign-in.service';
import { map, switchMap } from 'rxjs/operators';
import { isPending } from '@carabiner/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'carabiner-token-sign-in',
  templateUrl: './token-sign-in.component.html',
  styleUrls: ['./token-sign-in.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenSignInComponent {
  errors$ = this.signInService.errors$;
  isPending$: Observable<boolean>;

  constructor(
    private readonly router: ActivatedRoute,
    private readonly signInService: SignInService
  ) {
    this.signInService.clearErrors();
    this.isPending$ = this.router.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const token = params.get('token');
        this.signInService.signInViaToken(token);
        return this.signInService.signInStatus$.pipe(map(isPending));
      })
    );
  }
}
