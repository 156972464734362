import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityTimerComponent } from './activity-timer.component';
import { MilliSecondsToStopwatchStringModule } from '@carabiner/angular-shared/pipes';

@NgModule({
  declarations: [ActivityTimerComponent],
  exports: [ActivityTimerComponent],
  imports: [CommonModule, MilliSecondsToStopwatchStringModule],
})
export class ActivityTimerModule {}
