// this code copied from luxon

const isoDuration =
  /^-?P(?:(?:(-?\d{1,9})Y)?(?:(-?\d{1,9})M)?(?:(-?\d{1,9})W)?(?:(-?\d{1,9})D)?(?:T(?:(-?\d{1,9})H)?(?:(-?\d{1,9})M)?(?:(-?\d{1,20})(?:[.,](-?\d{1,9}))?S)?)?)$/;

function parse(s: string, ...patterns: any[]) {
  if (s == null) {
    return [null, null];
  }

  for (const [regex, extractor] of patterns) {
    const m = regex.exec(s);
    if (m) {
      return extractor(m);
    }
  }
  return [null, null];
}

function isUndefined(o: any): o is undefined {
  return typeof o === 'undefined';
}

function parseInteger(string: string) {
  if (isUndefined(string) || string === null || string === '') {
    return undefined;
  } else {
    return parseInt(string, 10);
  }
}

function parseMillis(fraction: any) {
  // Return undefined (instead of 0) in these cases, where fraction is not set
  if (isUndefined(fraction) || fraction === null || fraction === '') {
    return undefined;
  } else {
    const f = parseFloat('0.' + fraction) * 1000;
    return Math.floor(f);
  }
}

function extractISODuration(match: string[]) {
  const [
    s,
    yearStr,
    monthStr,
    weekStr,
    dayStr,
    hourStr,
    minuteStr,
    secondStr,
    millisecondsStr,
  ] = match;

  const hasNegativePrefix = s[0] === '-';
  const maybeNegate = (num: number | undefined) =>
    num && hasNegativePrefix ? -num : num;

  return [
    Object.assign(
      {},
      yearStr ? { years: maybeNegate(parseInteger(yearStr)) } : null,
      monthStr ? { months: maybeNegate(parseInteger(monthStr)) } : null,
      weekStr ? { weeks: maybeNegate(parseInteger(weekStr)) } : null,
      dayStr ? { days: maybeNegate(parseInteger(dayStr)) } : null,
      hourStr ? { hours: maybeNegate(parseInteger(hourStr)) } : null,
      minuteStr ? { minutes: maybeNegate(parseInteger(minuteStr)) } : null,
      secondStr ? { seconds: maybeNegate(parseInteger(secondStr)) } : null,
      millisecondsStr
        ? { milliseconds: maybeNegate(parseMillis(millisecondsStr)) }
        : null
    ),
  ];
}

export function parseISODuration(s: string) {
  const [parsed] = parse(s, [isoDuration, extractISODuration]);
  return parsed ? parsed : {};
}
