import { FormSetup } from './core-types';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RelativeDistanceEvent } from '@carabiner/angular-shared/data-access';
import { mergeDeepRight, pathOr } from 'ramda';
import { OnDestroyService } from '@carabiner/angular-shared/ui';
import { ProgramStartAndEnd } from '../program-start-and-end';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { RelativeDistanceEventWithOverride } from '../event-to-event-with-override';
import {
  calculateRelativeDistanceStartDate,
  dateToLocalDateString,
} from '@carabiner/common-utils';

export interface RelativeDistanceEventFormModel {
  startDate: Date | null;
  startTime: string;
  duration: number;
}

interface RelativeDistanceEventFormConfigParams {
  ewo: RelativeDistanceEventWithOverride;
  onDestroyService: OnDestroyService;
  startAndEndDate$: ProgramStartAndEnd;
}

export function relativeDistanceEventFormConfig({
  ewo,
  startAndEndDate$,
  onDestroyService,
}: RelativeDistanceEventFormConfigParams): FormSetup {
  const fields: FormlyFieldConfig[] = [
    {
      key: 'startDate',
      type: 'datepicker',
      templateOptions: {
        label: 'Complete by',
        readonly: true,
        datepickerOptions: {
          disabled: true,
        },
        appearance: 'fill',
      },
      hooks: {
        onInit: (field) => {
          startAndEndDate$
            .pipe(
              takeUntil(onDestroyService.triggered$),
              distinctUntilChanged()
            )
            .subscribe(({ programStart, programEnd }) => {
              const event = ewo.event as RelativeDistanceEvent;
              // // todo previous program end !!
              // // todo previous program end !!
              const previousEventEnd = undefined;
              const params = {
                programStartDate: dateToLocalDateString(programStart),
                programEndDate: dateToLocalDateString(programEnd),
                event,
                previousEventEnd: previousEventEnd,
              };
              const startDate = calculateRelativeDistanceStartDate(params);
              field?.formControl?.patchValue(startDate);
            });
        },
      },
    },
    {
      type: 'flex-group',
      fieldGroup: [
        {
          key: 'startTime',
          type: 'time-picker',
          templateOptions: {
            label: 'Start time',
          },
        },
        {
          key: 'duration',
          type: 'duration-to-end-time',
          templateOptions: {
            label: 'duration',
          },
        },
      ],
    },
  ];

  const form = new UntypedFormGroup({
    startDate: new UntypedFormControl(),
    startTime: new UntypedFormControl(),
    duration: new UntypedFormControl(), // readonly but needed
  });

  return {
    fields,
    form,
  };
}

export function relativeDistanceEventToFormModelAndDetailBlock(
  ewo: RelativeDistanceEventWithOverride
): { details: string[]; model: RelativeDistanceEventFormModel } {
  return {
    model: relativeDistanceEventToFormModel(ewo.event),
    details: [],
  };
}

function relativeDistanceEventToFormModel(
  event: Partial<RelativeDistanceEvent>
): RelativeDistanceEventFormModel {
  return {
    startTime: pathOr('', ['startTime'], event),
    duration: pathOr(0, ['duration'], event),
    startDate: null,
  };
}

export function relativeDistanceEventModelToEventWithOverride({
  ewoOriginal,
  model,
}: {
  ewoOriginal: RelativeDistanceEventWithOverride;
  model: RelativeDistanceEventFormModel;
}): RelativeDistanceEventWithOverride {
  const { startTime, startDate } = model;
  const override = Object.assign(
    { ...ewoOriginal.override },
    startTime ? { startTime } : null,
    startDate ? { startDate } : null
  );

  return mergeDeepRight(ewoOriginal, { override });
}
