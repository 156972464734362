import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { TokenSignInComponent } from './token-sign-in/token-sign-in.component';
import { RequestMagicLinkComponent } from './request-magic-link/request-magic-link.component';
import { UnauthenticatedComponent } from './unauthenticated/unauthenticated.component';

const routes: Routes = [
  {
    path: 'sign-in-with-password',
    component: SignInComponent,
  },
  {
    path: 'sign-in',
    component: RequestMagicLinkComponent,
  },
  {
    path: 'token/:token',
    component: TokenSignInComponent,
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
  },
  {
    path: 'unauthenticated',
    component: UnauthenticatedComponent,
  },
  {
    path: '**',
    redirectTo: 'sign-in',
    pathMatch: 'full',
  },
];

export const AuthenticationRouting = RouterModule.forChild(routes);
