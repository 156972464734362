<mat-progress-bar
  mode="indeterminate"
  *ngIf="pending"
></mat-progress-bar>
<div class="authentication-container-wrapper">
  <div class="authentication-container-primary">
    <h2>{{title}}</h2>
    <carabiner-alert-errors-block [errors]="errors"></carabiner-alert-errors-block>
    <ng-content></ng-content>
  </div>
</div>
