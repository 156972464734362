<footer>
  <div>
    &copy; Rocket326 Pty Ltd
  </div>
  <ul>
    <li>
      <a [routerLink]="['/terms-of-service']">Terms of service</a>
    </li>
    <li>
      <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
    </li>
  </ul>
</footer>
