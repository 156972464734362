import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { AuthenticationRouting } from './authentication.routes';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatInputModule } from '@ngx-formly/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSuffixModule } from '@carabiner/angular-shared/formly';
import { ReactiveFormsModule } from '@angular/forms';
import { SignOutComponent } from './sign-out/sign-out.component';
import { TokenSignInComponent } from './token-sign-in/token-sign-in.component';
import { RequestMagicLinkComponent } from './request-magic-link/request-magic-link.component';
import { AuthenticationContainerModule } from './authentication-container/authentication-container.module';
import { UnauthenticatedComponent } from './unauthenticated/unauthenticated.component';
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [
    SignInComponent,
    SignOutComponent,
    TokenSignInComponent,
    RequestMagicLinkComponent,
    UnauthenticatedComponent,
  ],
  imports: [
    AuthenticationRouting,
    CommonModule,
    FormlyModule.forChild(),
    FormlyMatInputModule,
    MatIconModule,
    MatSuffixModule,
    MatButtonModule,
    ReactiveFormsModule,
    AuthenticationContainerModule,
  ],
})
export class AuthenticationModule {}
