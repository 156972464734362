<carabiner-authentication-container
  title="Sign out"
  [pending]="pending$ | async"
  [errors]="errors$ | async">
  <div *ngIf="rejected$ | async">
    <button mat-button (click)="signOut()">Sign out</button>
  </div>

  <div *ngIf="success$ | async" data-test-id="sign-out-success">
    <h3>Signed out successfully</h3>
    <ul>
      <li><a [routerLink]="'/'">Return home</a></li>
      <li><a [routerLink]="'./sign-in'">Sign In</a></li>
    </ul>
  </div>
</carabiner-authentication-container>
