import { NgModule } from "@angular/core";
import { FullscreenOverlayContainer, OverlayContainer } from "@angular/cdk/overlay";
import { FullScreenLoadingOverlayComponent } from './full-screen-loading-overlay.component';
import { CommonModule } from "@angular/common";
import { LoadingOverlayModule } from "../loading/loading-overlay.module";

@NgModule({
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer }
  ],
  imports: [
    LoadingOverlayModule,
    CommonModule
  ],
  declarations: [
    FullScreenLoadingOverlayComponent
  ]
})
export class FullScreenLoadingOverlayModule {}
