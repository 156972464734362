import { default as FRAGMENT_POSSIBLE_TYPES } from './generated/fragment-matchers';
import { onError } from '@apollo/client/link/error';
import { LoggerService } from '@carabiner/angular-shared/core-services';
import { RetryLink } from '@apollo/client/link/retry';
import {Router} from "@angular/router";
import {HttpLink} from "apollo-angular/http";
import {ApolloClientOptions, from, InMemoryCache} from "@apollo/client";
import {NgModule} from "@angular/core";
import {HttpClientModule} from "@angular/common/http";
import {APOLLO_OPTIONS, ApolloModule} from "apollo-angular";

const GRAPHQL_API = '/api/graphql'; // <-- add the URL of the GraphQL server here
// when using a interface as a query we need to set possible types
// see more here
// https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically

const errorLink = (loggerService: LoggerService, router: Router) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message, locations, path, extensions } = err;
        loggerService.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          extensions
        );

        if (extensions && extensions.code === 'UNAUTHENTICATED') {
          router
            .navigate(['/auth/unauthenticated'])
            .then(() =>
              loggerService.info('Redirect to /auth/unauthenticated')
            );
        }
      }
    }

    if (networkError) loggerService.error(`[Network error]: ${networkError}`);
  });

// we autogenerate our possible types via the codegen config in data access
export function createApollo(
  httpLink: HttpLink,
  loggerService: LoggerService,
  router: Router
): ApolloClientOptions<any> {
  const retryLink = new RetryLink();

  const link = from([
    retryLink,
    errorLink(loggerService, router),
    httpLink.create({ uri: GRAPHQL_API }),
  ]);

  return {
    link,
    cache: new InMemoryCache(FRAGMENT_POSSIBLE_TYPES as any),
  };
}

@NgModule({
  imports: [
    HttpClientModule,
    ApolloModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, LoggerService, Router],
    },
  ],
})
export class GraphQLModule {}
