import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AlertActions,
  AlertComponent,
  AlertContent,
  AlertTitle,
} from './alert.component';

@NgModule({
  declarations: [AlertComponent, AlertTitle, AlertContent, AlertActions],
  imports: [CommonModule],
  exports: [AlertComponent, AlertTitle, AlertContent, AlertActions],
})
export class AlertModule {}
