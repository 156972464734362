import { Injectable } from '@angular/core';
import {
  Overlay,
  OverlayRef,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FullScreenLoadingOverlayComponent } from './full-screen-loading-overlay.component';
import { FullScreenLoadingOverlayModule } from './full-screen-loading-overlay.module';

@Injectable({
  providedIn: FullScreenLoadingOverlayModule,
})
export class FullScreenLoadingOverlayService {
  ref?: OverlayRef;
  constructor(
    private overlay: Overlay,
    private scrollStrategyOptions: ScrollStrategyOptions
  ) {}

  startLoading(uniqueKey: string) {}

  stopLoading(uniqueKey: string) {}

  showLoadingSpinner() {}

  openOverlay() {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const overlayRef = this.overlay.create({
      scrollStrategy,
      hasBackdrop: true,
      width: '100%',
      height: '100%',
    });

    this.ref = overlayRef;
    const loadingOverlayPortal = new ComponentPortal(
      FullScreenLoadingOverlayComponent
    );
    const componentRef = overlayRef.attach(loadingOverlayPortal);
  }

  closeOverlay() {
    this?.ref?.detach();
  }
}
