import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from "@ngx-formly/core";
import { StartTimeEndTimeGroupTypeComponent } from "./start-time-end-time-group-type.component";



@NgModule({
  declarations: [
    StartTimeEndTimeGroupTypeComponent
  ],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        { name: 'start-time-end-time-group', component: StartTimeEndTimeGroupTypeComponent}
      ]
    })
  ]
})
export class StartTimeEndTimeGroupTypeModule { }
