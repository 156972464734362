import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfServiceComponent } from './terms-of-service.component';
import { RouterModule, Routes } from '@angular/router';
import { ContainerModule } from '../container/container.module';

const routes: Routes = [
  {
    path: '',
    component: TermsOfServiceComponent,
  },
];

@NgModule({
  declarations: [TermsOfServiceComponent],
  imports: [CommonModule, RouterModule.forChild(routes), ContainerModule],
})
export class TermsOfServiceModule {}
