import { last } from 'ramda';
import { add, differenceInDays } from 'date-fns';
import { dateToLocalDateString } from './date-to-local-date-string';

type LocalDate = string;
export interface GetDatesInRangeParams {
  start: LocalDate;
  end: LocalDate;
  groupByWeek?: {
    anchorDate: LocalDate;
  };
}

export interface DateRangeByWeek {
  weekNumber: number;
  weekTitle: string;
  dates: LocalDate[];
}

export function getDatesInRange({
  start,
  end,
}: Omit<GetDatesInRangeParams, 'groupByWeek'>): string[];
export function getDatesInRange({
  start,
  end,
  groupByWeek,
}: Required<GetDatesInRangeParams>): DateRangeByWeek[];
export function getDatesInRange({
  start,
  end,
  groupByWeek,
}: GetDatesInRangeParams): string[] | DateRangeByWeek[] {
  const dates = [start];
  const groupedByWeek = [
    {
      weekNumber: 1,
      weekTitle: 'Week 1',
      dates: [start],
    },
  ];

  const previousDate = () => last(dates) as string;
  let currentWeek = 1;
  let currentDay = getCurrentDayFromAnchorDate({
    anchorDate: groupByWeek?.anchorDate,
    start,
  });
  while (previousDate() < end) {
    const nextDay = add(new Date(previousDate()), { days: 1 });
    const nextDayLocalDateString = dateToLocalDateString(nextDay);
    if (currentDay == 7) {
      currentWeek = currentWeek + 1;
      groupedByWeek.push({
        weekNumber: currentWeek,
        weekTitle: `Week ${currentWeek}`,
        dates: [],
      });
    }
    if (currentDay < 7) {
      currentDay++;
    } else {
      currentDay = 1;
    }

    dates.push(nextDayLocalDateString);
    if (groupByWeek) {
      groupedByWeek[currentWeek - 1].dates.push(nextDayLocalDateString);
    }
  }

  if (groupByWeek) {
    return groupedByWeek;
  }
  return dates;
}

// index from 1 -> 7
// fairly defensive will always try to return a useful date
export function getCurrentDayFromAnchorDate({
  anchorDate,
  start,
}: {
  anchorDate?: LocalDate;
  start: LocalDate;
}) {
  if (!anchorDate) {
    return 1;
  }
  const result = Math.abs(
    differenceInDays(new Date(start), new Date(anchorDate)) + 1
  );
  if (result > 7) {
    // if result is way off return the modulus which should match the correct day
    return result % 7;
  }
  return result;
}
