import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataLogComponent } from "./data-log.component";
import { FormlyModule } from "@ngx-formly/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyMatInputModule } from "@ngx-formly/material/input";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [
    DataLogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    // mat
    MatButtonModule,

    // formly
    FormlyModule,
    FormlyMatInputModule,
  ],
  exports: [
    DataLogComponent
  ]
})
export class DataLogModule { }
