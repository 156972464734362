import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { OnDestroyService } from '@carabiner/angular-shared/ui';
import { SignInService } from '../sign-in.service';
import { invalidOrPending, isPending } from '@carabiner/models';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'carabiner-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OnDestroyService],
})
export class SignInComponent implements OnInit {
  model = {
    email: '',
    password: '',
  };
  fields: FormlyFieldConfig[] = [];
  form = new UntypedFormGroup({});

  showPassword$ = new BehaviorSubject(false);
  showPasswordIcon$ = this.showPassword$.pipe(
    map((v) => (v ? 'visibility' : 'visibility_off'))
  );

  errors$ = this.signInService.errors$;
  pending$ = this.signInService.signInStatus$.pipe(map(isPending));

  disableButton$ = combineLatest([
    this.signInService.signInStatus$,
    this.form.statusChanges.pipe(startWith('INVALID')),
  ]).pipe(map(invalidOrPending));

  constructor(
    @Self() readonly onDestroyService: OnDestroyService,
    private signInService: SignInService
  ) {}

  ngOnInit(): void {
    this.signInService.clearErrors();
    const emailField = {
      key: 'email',
      type: 'input',
      id: 'sign-in-email',
      'data-test': 'bar',
      templateOptions: {
        label: 'Email',
        type: 'email',
        placeholder: 'your_email@domain.com',
        required: true,
        appearance: 'fill',
      },
      validators: {
        validation: ['email'],
      },
    };

    const passwordField = {
      key: 'password',
      type: 'input',
      id: 'sign-in-password',
      templateOptions: {
        label: 'Password',
        placeholder: 'secret',
        required: true,
        appearance: 'fill',
        type: 'password',
        matSuffix: {
          icon: this.showPasswordIcon$,
          onClick: () => this.togglePasswordVisibility(),
        },
      },
    };

    this.fields = [emailField, passwordField];

    this.showPassword$
      .pipe(
        takeUntil(this.onDestroyService.triggered$),
        map((v) => (v ? 'text' : 'password'))
      )
      .subscribe((v) => {
        passwordField.templateOptions.type = v;
      });
  }

  togglePasswordVisibility() {
    const currentValue = this.showPassword$.getValue();
    this.showPassword$.next(!currentValue);
  }

  signIn() {
    this.signInService.signIn({
      email: this.model.email,
      password: this.model.password,
    });
  }
}
