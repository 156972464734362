import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramCardComponent } from "./program-card.component";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    RouterModule
  ],
  declarations: [ProgramCardComponent],
  exports: [ProgramCardComponent]
})
export class ProgramCardModule {}
