import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'carabiner-day-picker-formly-type',
  templateUrl: './day-picker-formly-type.component.html',
  styleUrls: ['./day-picker-formly-type.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayPickerFormlyTypeComponent extends FieldType {}
