import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: 'carabiner-flex-group.type',
  templateUrl: './flex-group.type.component.html',
  styleUrls: ['./flex-group.type.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexGroupTypeComponent extends FieldType {

}
