import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Directive,
  ElementRef,
  Input,
} from '@angular/core';

export type AlertType = 'info' | 'error';
const defaultType: AlertType = 'info';

@Component({
  selector: 'carabiner-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  _type?: AlertType;

  constructor(private _elementRef: ElementRef) {}

  @Input()
  set type(value: AlertType) {
    const type = value || defaultType;

    if (type !== this._type) {
      if (this._type) {
        this._elementRef.nativeElement.classList.remove(
          `carabiner-alert-${this._type}`
        );
      }
      if (type) {
        this._elementRef.nativeElement.classList.add(`carabiner-alert-${type}`);
      }

      this._type = type;
    }
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'carabiner-alert-title',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AlertTitle {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'carabiner-alert-content',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AlertContent {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'carabiner-alert-actions',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AlertActions {}
