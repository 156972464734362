import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { StateDataFragmentDoc } from '../state/state-data.fragment.generated';
import { SessionLogFragmentDoc } from '../session-log/session-log.fragment.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserProgramSubscriptionLogsQueryVariables = Types.Exact<{
  programSubscriptionId: Types.Scalars['ID']['input'];
}>;


export type UserProgramSubscriptionLogsQuery = { __typename?: 'Query', userProgramSubscriptionLogs: Array<{ __typename?: 'InitialiseLog', id: string, type: Types.UserProgramSubscriptionLogType, currentState: Array<{ __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null } | { __typename?: 'StringState', key: string, namespace: Types.VariableNamespace, namespaceId: string, stringValue: string }> } | { __typename?: 'SessionLog', id: string, type: Types.UserProgramSubscriptionLogType, startDateTime: string, endDateTime?: string | null, duration?: string | null, eventId: string, currentState: Array<{ __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null } | { __typename?: 'StringState', key: string, namespace: Types.VariableNamespace, namespaceId: string, stringValue: string }>, activityLogs: Array<{ __typename?: 'ActivityLog', trackCompletion?: { __typename?: 'TrackCompletion', trackId: string, values: Array<{ __typename?: 'CompletionValue', status: Types.TrackStatus, numberValue: number, setIndex?: number | null, setBlockIndex?: number | null }>, trackedState?: Array<{ __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null }> | null } | null }> }> };

export const UserProgramSubscriptionLogsDocument = gql`
    query userProgramSubscriptionLogs($programSubscriptionId: ID!) {
  userProgramSubscriptionLogs(programSubscriptionId: $programSubscriptionId) {
    ... on CoreLog {
      id
      currentState {
        ...StateData
      }
      type
    }
    ... on SessionLog {
      ...SessionLog
    }
  }
}
    ${StateDataFragmentDoc}
${SessionLogFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserProgramSubscriptionLogsGQL extends Apollo.Query<UserProgramSubscriptionLogsQuery, UserProgramSubscriptionLogsQueryVariables> {
    document = UserProgramSubscriptionLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }