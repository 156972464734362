import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  RequestMagicLinkTokenGQL,
  RequestMagicLinkTokenMutation,
} from '@carabiner/angular-shared/data-access';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import {
  COMMON_STATUS,
  invalidOrPending,
  isPending,
  isResolved,
} from '@carabiner/models';
import { FetchResult } from '@apollo/client';
import { GraphQLError } from 'graphql';

@Component({
  selector: 'carabiner-request-magic-link',
  templateUrl: './request-magic-link.component.html',
  styleUrls: ['./request-magic-link.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestMagicLinkComponent implements OnInit, OnDestroy {
  status$ = new BehaviorSubject(COMMON_STATUS.idle);
  pending$ = this.status$.pipe(map(isPending));
  resolved$ = this.status$.pipe(map(isResolved));

  errors$ = new BehaviorSubject<null | string[]>(null);
  destroy$ = new Subject();

  fields: FormlyFieldConfig[] = [];
  model = {
    email: '',
  };
  form = new UntypedFormGroup({});

  disableButton$ = combineLatest([
    this.status$,
    this.form.statusChanges.pipe(startWith('INVALID')),
  ]).pipe(map(invalidOrPending));

  constructor(
    private readonly requestMagicLinkToken: RequestMagicLinkTokenGQL
  ) {}

  ngOnInit(): void {
    const emailField = {
      key: 'email',
      type: 'input',
      id: 'sign-in-email',
      'data-test': 'bar',
      templateOptions: {
        label: 'Email',
        type: 'email',
        placeholder: 'your_email@domain.com',
        required: true,
        appearance: 'fill',
      },
      validators: {
        validation: ['email'],
      },
    };

    this.fields = [emailField];
  }

  requestMagicLink() {
    const email = this.form.get('email')?.value;
    this.status$.next(COMMON_STATUS.pending);
    this.errors$.next(null);

    const input = { email };
    this.requestMagicLinkToken
      .mutate({ input })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result: FetchResult<RequestMagicLinkTokenMutation>) => {
          // server always returns a true result
          // so we dont give away our users email addresses
          this.status$.next(COMMON_STATUS.resolved);
        },
        (err) => {
          const errorCodes: string[] =
            err?.graphQLErrors?.map(
              (err: GraphQLError) => err?.extensions?.code
            ) || [];
          if (errorCodes.includes('EMAIL_ERROR')) {
            this.errors$.next([
              'Error sending email. We have been notified. Please try again later.',
            ]);
          } else {
            this.errors$.next([
              'Network error please check your connection and try again.',
            ]);
          }
          this.status$.next(COMMON_STATUS.rejected);
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
