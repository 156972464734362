import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'carabiner-loading-component, [loading]',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements OnDestroy {
  observable?: Observable<boolean>;
  subscription?: Subscription;
  @HostBinding('class.apply-overlay') applyOverlay = false;

  @Input()
  @HostBinding('class.full-width')
  fullWidth = false;

  @Input()
  @HostBinding('class.full-height')
  fullHeight = false;

  @Input()
  @HostBinding('class.has-backdrop')
  hasBackdrop = true;

  @Input() set loading(value: boolean | Observable<boolean>) {
    if (isObservable(value)) {
      this.updateObservable(value);
      return;
    }
    this.updateValue(value);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  updateObservable(value: Observable<boolean>): void {
    if (this.observable === value) {
      return;
    }
    this.observable = value;
    this.cleanUpSubscriptionIfRequired();
    this.subscription = this.observable
      .pipe(distinctUntilChanged())
      .subscribe((obs_value) => {
        this.updateValue(obs_value);
      });
  }

  updateValue(value: boolean) {
    if (value === this.applyOverlay) {
      return;
    }
    this.applyOverlay = value;
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.cleanUpSubscriptionIfRequired();
  }

  cleanUpSubscriptionIfRequired() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

function isObservable(
  value: Observable<boolean> | boolean
): value is Observable<boolean> {
  return value instanceof Observable;
}
