import { Injectable, OnDestroy } from '@angular/core';
import { map, share, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { interval, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimeService implements OnDestroy {
  destroy$ = new Subject();
  time$: Observable<number> = interval(1000).pipe(
    map(() => Date.now()),
    startWith(Date.now()),
    map((v) => Math.round(v / 1000) * 1000),
    shareReplay(1)
  );

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  constructor() {
    this.time$.pipe(takeUntil(this.destroy$)).subscribe();
  }
}
