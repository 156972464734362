import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { NumberStateFragmentDoc } from '../state/state-data.fragment.generated';
export type SessionLogFragment = { __typename?: 'SessionLog', id: string, startDateTime: string, endDateTime?: string | null, duration?: string | null, eventId: string, activityLogs: Array<{ __typename?: 'ActivityLog', trackCompletion?: { __typename?: 'TrackCompletion', trackId: string, values: Array<{ __typename?: 'CompletionValue', status: Types.TrackStatus, numberValue: number, setIndex?: number | null, setBlockIndex?: number | null }>, trackedState?: Array<{ __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null }> | null } | null }> };

export const SessionLogFragmentDoc = gql`
    fragment SessionLog on SessionLog {
  id
  startDateTime
  endDateTime
  duration
  eventId
  activityLogs {
    trackCompletion {
      trackId
      values {
        status
        numberValue
        setIndex
        setBlockIndex
      }
      trackedState {
        ...NumberState
      }
    }
  }
}
    ${NumberStateFragmentDoc}`;