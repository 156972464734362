import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DayPickerFormlyTypeComponent } from './day-picker-formly-type/day-picker-formly-type.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { DayPickerFormControlComponent } from './day-picker-form-control/day-picker-form-control.component';
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
  declarations: [DayPickerFormlyTypeComponent, DayPickerFormControlComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'day-picker',
          component: DayPickerFormlyTypeComponent,
        },
      ],
      validationMessages: [
        {
          name: 'unavailableDaySelected',
          message: 'Select an available day',
        },
      ],
    }),
    MatFormFieldModule,
  ],
})
export class DayPickerModule {}
