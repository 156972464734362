import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SubscribeToProgramMutationVariables = Types.Exact<{
  input: Types.SubscribeToProgramInput;
}>;


export type SubscribeToProgramMutation = { __typename?: 'Mutation', subscribeToProgram: { __typename?: 'SubscribeToProgramResult', programSubscriptionId: string, confirmationEmail?: string | null } };

export const SubscribeToProgramDocument = gql`
    mutation subscribeToProgram($input: SubscribeToProgramInput!) {
  subscribeToProgram(input: $input) {
    programSubscriptionId
    confirmationEmail
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscribeToProgramGQL extends Apollo.Mutation<SubscribeToProgramMutation, SubscribeToProgramMutationVariables> {
    document = SubscribeToProgramDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }