import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { filter, map, startWith } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { add, parse } from 'date-fns';
import {
  formatDateAsDisplayTime,
  milliSecondsToDuration,
} from '@carabiner/common-utils';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'carabiner-duration-to-end-time-type',
  templateUrl: './duration-to-end-time.type.component.html',
  styleUrls: ['./duration-to-end-time.type.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationToEndTimeTypeComponent
  extends FieldType
  implements OnInit
{
  endTime$!: Observable<string>;
  ngOnInit() {
    const field = this.field;

    const startTimeControl = field?.parent?.formControl?.get('startTime');
    if (!startTimeControl) {
      throw Error(
        `Duration to end time type must be in a field group with a 'startTime' field`
      );
    }

    const initialStartTimeValue = startTimeControl.value as string;
    const startTime$: Observable<string> = startTimeControl.valueChanges.pipe(
      startWith(initialStartTimeValue)
    );

    const durationControl = field.formControl as UntypedFormControl;
    const initialDuration = durationControl?.value as number;
    const duration$: Observable<number> = durationControl?.valueChanges.pipe(
      startWith(initialDuration),
      filter((v) => typeof v === 'number')
    );

    this.endTime$ = combineLatest([startTime$, duration$]).pipe(
      map(([startTime, duration]) => {
        // todo change date format to not include Z for UTC
        const date = parse(startTime.slice(0, 8), 'HH:mm:ss', new Date());
        const endTime = add(date, milliSecondsToDuration(duration));
        return formatDateAsDisplayTime(endTime);
      })
    );
  }
}
