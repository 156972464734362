import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// this service needs to be imported in the app component
// to work with the root of the routes tree
@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService implements OnDestroy {
  private destroy$ = new Subject();
  previousUrl: string | null = null;
  currentUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.currentUrl === '/auth/sign-out') {
          this.previousUrl = '/';
        } else {
          this.previousUrl = this.currentUrl;
        }
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public previousUrlIncludes(urlValueToCheck: string): boolean {
    if (this.previousUrl && this.previousUrl.includes(urlValueToCheck)) {
      return true;
    }
    return false;
  }

  public backToPreviousRoute() {
    return this.router.navigate([this.getPreviousUrl()]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
