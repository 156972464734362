<carabiner-authentication-container
  title="Request magic link"
  [pending]="pending$ | async"
  [errors]="errors$ | async"
>
  <ng-container *ngIf="resolved$ | async; else requestToken">
    <p data-test-id="request-magic-token-success-message">We just emailed <strong>{{model?.email}}</strong> with a link to login.</p>
    <h4>Check your email and click the link to login.</h4>
    <a [routerLink]="['/']">Return home</a>
  </ng-container>
  <ng-template #requestToken>
    <p>A magic link set to your email that will sign you in instantly!</p>
    <form [formGroup]="form" (submit)="requestMagicLink()">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      <button
        [disabled]="disableButton$ | async"
        mat-raised-button
        color="primary"
        type="submit"
      >
        Request magic link
      </button>
    </form>
  </ng-template>
</carabiner-authentication-container>
