import {
  RelativeDistanceEvent,
  RepeatingEvent,
  Event,
} from '@carabiner/models';

// the post-fixed type guards can be used in the frontend
// the base guards can be used in the type-graphql resolvers

export function isRepeatingEventType(
  value: any
): value is Partial<RepeatingEvent> {
  return value?.__typename === 'RepeatingEvent';
}

export function isRelativeDistanceEventType(
  value: any
): value is Partial<RelativeDistanceEvent> {
  return value?.__typename === 'RelativeDistanceEvent';
}
