import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProgramCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProgramCategoriesQuery = { __typename?: 'Query', programCategories: Array<{ __typename?: 'ProgramCategory', categoryTitle: string, icon: { __typename?: 'Icon', name: string }, programs: Array<{ __typename?: 'Program', title: string, id: string, icon: { __typename?: 'Icon', name: string }, timeCommitment: { __typename?: 'TimeCommitment', unit: string, period: string, value: number }, author: { __typename?: 'Author', firstName: string, lastName: string } }> }> };

export const ProgramCategoriesDocument = gql`
    query programCategories {
  programCategories {
    icon {
      name
    }
    categoryTitle
    programs {
      title
      id
      icon {
        name
      }
      timeCommitment {
        unit
        period
        value
      }
      author {
        firstName
        lastName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProgramCategoriesGQL extends Apollo.Query<ProgramCategoriesQuery, ProgramCategoriesQueryVariables> {
    document = ProgramCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }