import { add, differenceInDays, parse } from 'date-fns';
import { TimeDistanceUnit, RelativeDistanceEvent } from '@carabiner/models';
import { milliSecondsToDuration } from './milli-seconds-to-duration';
import { calculateStartDate } from './calculate-start-date';

interface CalculateRelativeDistanceStartDateParams {
  programStartDate: string;
  programEndDate: string;
  previousEventEnd: string | Date | undefined;
  event: Pick<
    RelativeDistanceEvent,
    'startTime' | 'relativeTo' | 'unit' | 'distance' | 'duration'
  >;
}

export function calculateRelativeDistanceStartDate(
  params: CalculateRelativeDistanceStartDateParams
): Date {
  const { programStartDate, programEndDate, previousEventEnd, event } = params;
  const { startTime, distance, unit, relativeTo, duration } = event;
  const earliestEventStartDate = calculateStartDate({
    previousEventEnd,
    programStartDate,
    relativeTo,
  });
  const startBase = parse(
    `${startTime}Z`,
    'HH:mm:ssX',
    new Date(earliestEventStartDate)
  );

  let daysToAdd = 0;
  if (unit === TimeDistanceUnit.PercentOfProgramPeriod) {
    const end = add(
      parse(`${startTime}Z`, 'HH:mm:ssX', new Date(programEndDate)),
      milliSecondsToDuration(duration)
    );
    const programLength = differenceInDays(end, startBase);
    daysToAdd = Math.round(programLength * (distance / 100));
  }

  return add(startBase, { days: daysToAdd });
}
