import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export type ProgramStartAndEnd = Observable<{
  programStart: Date;
  programEnd: Date;
}>;

export function startAndEndDate(
  programStart$: Observable<Date>,
  programEnd$: Observable<Date>
): ProgramStartAndEnd {
  return combineLatest([programStart$, programEnd$]).pipe(
    shareReplay(1),
    map(([programStart, programEnd]) => ({ programStart, programEnd }))
  );
}
