import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'programs', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('@carabiner/angular-shared/authentication').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('./subscriptions/subscriptions.module').then(
        (m) => m.SubscriptionsModule
      ),
  },
  {
    path: 'program-subscription',
    loadChildren: () =>
      import('./program-subscription/program-subscription.module').then(
        (m) => m.ProgramSubscriptionModule
      ),
  },
  {
    path: 'programs',
    loadChildren: () =>
      import('./programs/programs.module').then((m) => m.ProgramsModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'typography',
    loadChildren: () =>
      import('./typography-preview/typography-preview.module').then(
        (m) => m.TypographyPreviewModule
      ),
  },
  {
    path: 'terms-of-service',
    loadChildren: () =>
      import('@carabiner/angular-shared/ui').then(
        (m) => m.TermsOfServiceModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('@carabiner/angular-shared/ui').then((m) => m.PrivacyPolicyModule),
  },
];

export const AppRouting = RouterModule.forRoot(routes, {});
