import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePickerFormlyTypeComponent } from "./time-picker-type/time-picker.type.component";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { TimePickerControlComponent } from './time-picker-control/time-picker-control.component';
import { TimePickerFormlyTypeConfig } from "./time-picker-type/time-picker.type.config";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  declarations: [TimePickerFormlyTypeComponent, TimePickerControlComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forChild(TimePickerFormlyTypeConfig),
    MatSelectModule
  ]
})
export class TimePickerModule { }
