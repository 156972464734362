import { Pipe, PipeTransform } from '@angular/core';
import { milliSecondsToDuration } from '@carabiner/common-utils';
import { formatDuration } from 'date-fns';
import { LoggerService } from '@carabiner/angular-shared/core-services';

const LOG_PREFIX = '[MilliSecondsToDurationStringPipe]';

@Pipe({
  name: 'milliSecondsToDurationString',
})
export class MilliSecondsToDurationStringPipe implements PipeTransform {
  constructor(private logger: LoggerService) {}

  transform(value: number): string {
    try {
      const result = formatDuration(milliSecondsToDuration(value));
      if (result.length < 1) {
        this.logger.error(
          `${LOG_PREFIX} error formatting duration. Duration should be in milliseconds.`,
          {
            value,
          }
        );
        return `Error: duration!`;
      }
      return result;
    } catch (error) {
      this.logger.error(`${LOG_PREFIX} error formatting duration`, {
        error,
        value,
      });
      return `Error: duration!`;
    }
  }
}
