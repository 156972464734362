import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'carabiner-mat-suffix',
  templateUrl: './mat-suffix.component.html',
  styleUrls: ['./mat-suffix.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MatSuffixComponent extends FieldWrapper implements AfterViewInit {
  icon$!: Observable<string> | null;

  @ViewChild('matSuffix') matSuffix!: TemplateRef<any>;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    if (this?.matSuffix && this?.to?.matSuffix?.icon) {
      Promise.resolve().then(() => {
        this.to.suffix = this.matSuffix;
        if (this.to.matSuffix.icon instanceof Observable) {
          this.icon$ = this.to.matSuffix.icon;
        } else {
          this.icon$ = of(this.to.matSuffix.icon);
        }
        this.to.suffix = this.matSuffix;
        this.cdr.markForCheck();
      });
    }
  }

  suffixClick() {
    if (this?.to?.matSuffix?.onClick) {
      this.to.matSuffix.onClick();
    }
  }
}
