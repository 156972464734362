export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: { input: string; output: string; }
  /**
   * A string representing a duration conforming to the ISO8601 standard,
   * such as: P1W1DT13H23M34S
   * P is the duration designator (for period) placed at the start of the duration representation.
   * Y is the year designator that follows the value for the number of years.
   * M is the month designator that follows the value for the number of months.
   * W is the week designator that follows the value for the number of weeks.
   * D is the day designator that follows the value for the number of days.
   * T is the time designator that precedes the time components of the representation.
   * H is the hour designator that follows the value for the number of hours.
   * M is the minute designator that follows the value for the number of minutes.
   * S is the second designator that follows the value for the number of seconds.
   *
   * Note the time designator, T, that precedes the time value.
   *
   * Matches moment.js, Luxon and DateFns implementations
   * ,/. is valid for decimal places and +/- is a valid prefix
   */
  ISO8601Duration: { input: string; output: string; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: object; output: object; }
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: { input: string; output: string; }
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`. */
  LocalTime: { input: string; output: string; }
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: number; output: number; }
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: number; output: number; }
}

export interface ActionButtonContentBlock extends BaseContentBlock {
  __typename?: 'ActionButtonContentBlock';
  action: ButtonContentBlockActions;
  innerText: Scalars['String']['output'];
  logicRule?: Maybe<LogicRule>;
  type: ContentBlockType;
}

export interface ActivityLog {
  __typename?: 'ActivityLog';
  dateTime: Scalars['String']['output'];
  numberValues?: Maybe<Array<NumberState>>;
  stringValues?: Maybe<Array<StringState>>;
  trackCompletion?: Maybe<TrackCompletion>;
  type: ActivityLogType;
}

export interface ActivityLogInput {
  dateTime: Scalars['String']['input'];
  numberValues?: InputMaybe<Array<NumberStateInput>>;
  stringValues?: InputMaybe<Array<StringStateInput>>;
  trackCompletion?: InputMaybe<TrackCompletionInput>;
  type: ActivityLogType;
}

/** The activity log type */
export enum ActivityLogType {
  CompleteTrack = 'CompleteTrack',
  LoadSession = 'LoadSession',
  SetVars = 'SetVars'
}

export interface Appointment {
  __typename?: 'Appointment';
  date: Scalars['LocalDate']['output'];
  eventId: Scalars['ID']['output'];
  generatedBy: AppointmentGeneratedBy;
  startTime: Scalars['String']['output'];
}

/** RRule is generated by the RRule library, User indicates the user manually created the entry */
export enum AppointmentGeneratedBy {
  RRule = 'RRule',
  User = 'User'
}

export interface AudioContentBlock extends BaseContentBlock {
  __typename?: 'AudioContentBlock';
  fileSrc: Scalars['String']['output'];
  logicRule?: Maybe<LogicRule>;
  soundPosition?: Maybe<SoundPosition>;
  soundPositionOffset?: Maybe<Scalars['PositiveInt']['output']>;
  type: ContentBlockType;
}

export interface Author {
  __typename?: 'Author';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
}

export interface BaseContentBlock {
  logicRule?: Maybe<LogicRule>;
  type: ContentBlockType;
}

export interface BaseEvent {
  content: Array<EventContent>;
  description: Scalars['String']['output'];
  duration: Scalars['NonNegativeInt']['output'];
  id: Scalars['ID']['output'];
  /** Title of event, appears as summary in ical event */
  title: Scalars['String']['output'];
  variableDefinitions: Array<VariableDefinition>;
}

export interface BaseTrack {
  hooks?: Maybe<Array<UpdateStateTrackHook>>;
  id: Scalars['ID']['output'];
  logicRule?: Maybe<LogicRule>;
  title: Scalars['String']['output'];
  variableConfigurations?: Maybe<VariableConfiguration>;
  variableDefinitions?: Maybe<Array<VariableDefinition>>;
}

/** Button actions */
export enum ButtonContentBlockActions {
  SignUp = 'SignUp',
  StartPlaylist = 'StartPlaylist'
}

/** A custom unit const or custom unit ref var - the ref can be a plain number */
export type CCustomUnit = CustomUnitConst | CustomUnitRef;

/** A distance const or distance ref var - the ref can be a plain number */
export type CDistance = DistanceConst | DistanceRef;

/** A number const or number ref var */
export type CNumber = NumberConst | VariableRef;

export type CString = StringValue | VariableRef;

/** A weight const or weight ref var - the ref can be a plain number */
export type CWeight = WeightConst | WeightRef;

export interface CompletionValue extends NumberValue {
  __typename?: 'CompletionValue';
  numberValue: Scalars['Float']['output'];
  setBlockIndex?: Maybe<Scalars['Float']['output']>;
  setIndex?: Maybe<Scalars['Float']['output']>;
  status: TrackStatus;
  targetNumberValue: Scalars['Float']['output'];
}

export interface CompletionValueInput {
  numberValue: Scalars['Float']['input'];
  setBlockIndex?: InputMaybe<Scalars['Float']['input']>;
  setIndex?: InputMaybe<Scalars['Float']['input']>;
  status: TrackStatus;
  targetNumberValue: Scalars['Float']['input'];
}

export interface ContentBlockFormFieldDefinition {
  __typename?: 'ContentBlockFormFieldDefinition';
  templateOptions: ContentBlockTemplateOptions;
  type: FormFieldType;
}

export interface ContentBlockTemplateOptions {
  __typename?: 'ContentBlockTemplateOptions';
  content: Array<FormContentBlock>;
}

/** The type of content block, required for all content blocks */
export enum ContentBlockType {
  ActionButton = 'ActionButton',
  Audio = 'Audio',
  CountDownTimer = 'CountDownTimer',
  DataLog = 'DataLog',
  HorizontalScroll = 'HorizontalScroll',
  Html = 'Html',
  Image = 'Image',
  OverlayContent = 'OverlayContent',
  Playlist = 'Playlist',
  PlaylistNextTrackPreview = 'PlaylistNextTrackPreview',
  PlaylistTrackControls = 'PlaylistTrackControls',
  ProgramHeroHeading = 'ProgramHeroHeading',
  Start = 'Start',
  TimedExerciseHtmlContentBlock = 'TimedExerciseHtmlContentBlock',
  TitleOfParentObject = 'TitleOfParentObject',
  TrackHeroTitle = 'TrackHeroTitle'
}

export interface CoreLog {
  currentState: Array<StateData>;
  id: Scalars['ID']['output'];
  type: UserProgramSubscriptionLogType;
}

export interface CoreUser {
  __typename?: 'CoreUser';
  email: Scalars['EmailAddress']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
}

export interface CreateUser {
  email: Scalars['EmailAddress']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface CurrentUserState {
  __typename?: 'CurrentUserState';
  schedule: Array<Appointment>;
  state: Array<StateData>;
  system: SystemGeneratedVariables;
}

export interface CustomUnitConst extends NumberValue {
  __typename?: 'CustomUnitConst';
  customUnit: Scalars['String']['output'];
  numberValue: Scalars['Float']['output'];
}

export interface CustomUnitRef {
  __typename?: 'CustomUnitRef';
  customUnit: Scalars['String']['output'];
  variableRef: Scalars['String']['output'];
}

export interface CustomUnitVariableDefinition {
  __typename?: 'CustomUnitVariableDefinition';
  defaultCustomUnitValue: CCustomUnit;
  key: Scalars['String']['output'];
  max?: Maybe<CNumber>;
  min?: Maybe<CNumber>;
  type: NumberType;
  userEditable?: Maybe<Scalars['Boolean']['output']>;
  validCustomUnits?: Maybe<Array<Scalars['String']['output']>>;
}

export interface DataLogBlock extends BaseContentBlock {
  __typename?: 'DataLogBlock';
  dataLogForm: Array<FormField>;
  dataLogVariableDefaults: Scalars['JSONObject']['output'];
  logicRule?: Maybe<LogicRule>;
  saveCondition: DataLogSaveCondition;
  type: ContentBlockType;
}

/** When the data log will be persisted */
export enum DataLogSaveCondition {
  OnLoad = 'OnLoad',
  OnSubmit = 'OnSubmit',
  OnTransition = 'OnTransition'
}

/** A restriction on the day the event can be scheduled for */
export enum DayRestriction {
  AfterPreviousBeforeFirst = 'AfterPreviousBeforeFirst',
  StartDate = 'StartDate'
}

export interface DistanceConst extends NumberValue {
  __typename?: 'DistanceConst';
  distanceUnit: DistanceUnit;
  numberValue: Scalars['Float']['output'];
}

export interface DistanceRef {
  __typename?: 'DistanceRef';
  refDistanceUnit?: Maybe<DistanceUnit>;
  variableRef: Scalars['String']['output'];
}

/** Unit for Distance category (km, mi) */
export enum DistanceUnit {
  km = 'km',
  m = 'm',
  mi = 'mi',
  yd = 'yd'
}

export interface DistanceVariableDefinition {
  __typename?: 'DistanceVariableDefinition';
  imperialDistanceDefault: CDistance;
  key: Scalars['String']['output'];
  max?: Maybe<CNumber>;
  metricDistanceDefault: CDistance;
  min?: Maybe<CNumber>;
  type: NumberType;
  userEditable?: Maybe<Scalars['Boolean']['output']>;
  validDistanceUnits?: Maybe<Array<DistanceUnit>>;
}

export interface DurationConfig {
  __typename?: 'DurationConfig';
  mode: ProgramDurationMode;
  value: Scalars['ISO8601Duration']['output'];
}

export type Event = RelativeDistanceEvent | RepeatingEvent;

export type EventContent = ActionButtonContentBlock | DataLogBlock | HtmlContentBlock | PlaylistContentBlock;

export interface FinishedTrack extends BaseTrack {
  __typename?: 'FinishedTrack';
  content: Array<PlaylistTrackContentBlock>;
  hideFromSummaryList: Scalars['Boolean']['output'];
  hooks?: Maybe<Array<UpdateStateTrackHook>>;
  id: Scalars['ID']['output'];
  logicRule?: Maybe<LogicRule>;
  title: Scalars['String']['output'];
  variableConfigurations?: Maybe<VariableConfiguration>;
  variableDefinitions?: Maybe<Array<VariableDefinition>>;
}

export type FormContentBlock = HtmlContentBlock | OverlayContentBlock;

export type FormField = ContentBlockFormFieldDefinition | InputFormFieldDefinition | SliderFormFieldDefinition;

/** FormFieldType */
export enum FormFieldType {
  checkbox = 'checkbox',
  contentblock = 'contentblock',
  input = 'input',
  radio = 'radio',
  select = 'select',
  slider = 'slider',
  textarea = 'textarea',
  toggle = 'toggle'
}

export type HorizontalScrollContent = ImageContentBlock;

export interface HorizontalScrollContentBlock extends BaseContentBlock {
  __typename?: 'HorizontalScrollContentBlock';
  content: Array<HorizontalScrollContent>;
  logicRule?: Maybe<LogicRule>;
  type: ContentBlockType;
}

export interface HtmlContentBlock extends BaseContentBlock {
  __typename?: 'HtmlContentBlock';
  html: Scalars['String']['output'];
  includeInPreview?: Maybe<Scalars['Boolean']['output']>;
  logicRule?: Maybe<LogicRule>;
  styles?: Maybe<Scalars['String']['output']>;
  type: ContentBlockType;
}

export interface Icon {
  __typename?: 'Icon';
  name: Scalars['String']['output'];
}

export interface ImageContentBlock extends BaseContentBlock {
  __typename?: 'ImageContentBlock';
  logicRule?: Maybe<LogicRule>;
  src: Scalars['String']['output'];
  type: ContentBlockType;
}

export interface InitialiseLog extends CoreLog {
  __typename?: 'InitialiseLog';
  currentState: Array<StateData>;
  id: Scalars['ID']['output'];
  type: UserProgramSubscriptionLogType;
}

/** InputFieldType */
export enum InputFieldType {
  number = 'number',
  text = 'text'
}

export interface InputFormFieldDefinition {
  __typename?: 'InputFormFieldDefinition';
  key: Scalars['String']['output'];
  templateOptions: InputFormFieldTemplateOptions;
  type: FormFieldType;
}

export interface InputFormFieldTemplateOptions {
  __typename?: 'InputFormFieldTemplateOptions';
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  placeholder?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<InputFieldType>;
}

export interface LogCurrentUserSessionResponse {
  __typename?: 'LogCurrentUserSessionResponse';
  sessionLog: SessionLog;
}

export interface LogicRule {
  __typename?: 'LogicRule';
  logic: Scalars['JSONObject']['output'];
  type: LogicRuleType;
}

/** The rule application type - include or exclude content */
export enum LogicRuleType {
  Exclude = 'Exclude',
  Include = 'Include'
}

export interface Mutation {
  __typename?: 'Mutation';
  logCurrentUserSession: LogCurrentUserSessionResponse;
  register: Scalars['Boolean']['output'];
  requestMagicLinkToken: Scalars['Boolean']['output'];
  sendTestNodeMailerEmail: Scalars['Boolean']['output'];
  sendTestSESEmail: Scalars['Boolean']['output'];
  signIn: SignInResponse;
  signInViaToken: SignInResponse;
  signOut: Scalars['Boolean']['output'];
  subscribeToProgram: SubscribeToProgramResult;
}


export interface MutationLogCurrentUserSessionArgs {
  input: SessionLogInput;
}


export interface MutationRegisterArgs {
  input: CreateUser;
}


export interface MutationRequestMagicLinkTokenArgs {
  input: RequestMagicLinkToken;
}


export interface MutationSendTestNodeMailerEmailArgs {
  input: Scalars['String']['input'];
}


export interface MutationSendTestSesEmailArgs {
  input: Scalars['String']['input'];
}


export interface MutationSignInArgs {
  input: SignIn;
}


export interface MutationSignInViaTokenArgs {
  input: SignInViaToken;
}


export interface MutationSubscribeToProgramArgs {
  input: SubscribeToProgramInput;
}

export interface NumberConst extends NumberValue {
  __typename?: 'NumberConst';
  numberValue: Scalars['Float']['output'];
}

export interface NumberState {
  __typename?: 'NumberState';
  key: Scalars['String']['output'];
  namespace: VariableNamespace;
  /** ID for namespace, i.e program-143 (program namespace), event-324 (event namespace), track-343 (track namespace) */
  namespaceId: Scalars['String']['output'];
  numberValue: Scalars['Float']['output'];
  unit?: Maybe<Scalars['String']['output']>;
}

export interface NumberStateInput {
  key: Scalars['String']['input'];
  namespace: VariableNamespace;
  /** ID for namespace, i.e program-143 (program namespace), event-324 (event namespace), track-343 (track namespace) */
  namespaceId: Scalars['String']['input'];
  numberValue: Scalars['Float']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
}

/** A number type for variables (Int, PositiveInt, Float...) */
export enum NumberType {
  PositiveInt = 'PositiveInt'
}

export interface NumberValue {
  numberValue: Scalars['Float']['output'];
}

export interface NumberVariableDefinition {
  __typename?: 'NumberVariableDefinition';
  defaultNumber: CNumber;
  key: Scalars['String']['output'];
  max?: Maybe<CNumber>;
  min?: Maybe<CNumber>;
  type: NumberType;
  userEditable?: Maybe<Scalars['Boolean']['output']>;
}

/** Condition for hook to fire for track i.e on: Completed */
export enum NumberVariation {
  DecrementBy = 'DecrementBy',
  IncrementBy = 'IncrementBy',
  Set = 'Set'
}

export type OverlayContent = HtmlContentBlock;

export interface OverlayContentBlock extends BaseContentBlock {
  __typename?: 'OverlayContentBlock';
  content: Array<OverlayContent>;
  linkDescription: Scalars['String']['output'];
  logicRule?: Maybe<LogicRule>;
  type: ContentBlockType;
}

export interface PlaylistContentBlock extends BaseContentBlock {
  __typename?: 'PlaylistContentBlock';
  logicRule?: Maybe<LogicRule>;
  tracks: Array<PlaylistTrack>;
  type: ContentBlockType;
}

export type PlaylistTrack = FinishedTrack | RepeatedTrack | TimedTrack;

export type PlaylistTrackContentBlock = ActionButtonContentBlock | AudioContentBlock | HtmlContentBlock | SimpleContentBlock | TrackHeroTitleBlock;

export type PreviewProgramContentBlock = ActionButtonContentBlock | HorizontalScrollContentBlock | HtmlContentBlock | SimpleContentBlock;

export interface Program {
  __typename?: 'Program';
  author: Author;
  categoryIds: Array<Scalars['ID']['output']>;
  description: Scalars['String']['output'];
  duration: DurationConfig;
  events: Array<Event>;
  heroImage: Scalars['String']['output'];
  heroSwirl: Scalars['String']['output'];
  iCals?: Maybe<Array<Scalars['String']['output']>>;
  icon: Icon;
  id: Scalars['ID']['output'];
  previewProgramContent: Array<PreviewProgramContentBlock>;
  subscribeToProgramForm: Array<FormField>;
  timeCommitment: TimeCommitment;
  title: Scalars['String']['output'];
  variableDefinitions: Array<VariableDefinition>;
}


export interface ProgramEventsArgs {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface ProgramCategory {
  __typename?: 'ProgramCategory';
  categoryTitle: Scalars['String']['output'];
  icon: Icon;
  id: Scalars['ID']['output'];
  programs: Array<Program>;
}

/** The type for the program duration. Fixed, Any */
export enum ProgramDurationMode {
  Fixed = 'Fixed',
  Variable = 'Variable'
}

export interface ProgramSubscription {
  __typename?: 'ProgramSubscription';
  currentUserState: CurrentUserState;
  id: Scalars['ID']['output'];
  program: Program;
  subscribers: Array<CoreUser>;
}

export type ProgramSubscriptionLog = InitialiseLog | SessionLog;

export interface Query {
  __typename?: 'Query';
  currentUser: User;
  currentUserSubscriptions: Array<ProgramSubscription>;
  /** @deprecated Use program to access event */
  event: Event;
  program: Program;
  programCategories: Array<ProgramCategory>;
  programSubscription: ProgramSubscription;
  userProgramSubscriptionLogs: Array<ProgramSubscriptionLog>;
}


export interface QueryEventArgs {
  id: Scalars['ID']['input'];
  programId: Scalars['ID']['input'];
}


export interface QueryProgramArgs {
  id: Scalars['ID']['input'];
}


export interface QueryProgramSubscriptionArgs {
  id: Scalars['ID']['input'];
}


export interface QueryUserProgramSubscriptionLogsArgs {
  programSubscriptionId: Scalars['ID']['input'];
}

/** Frequency option for ICAL use with interval and until to create complex patterns */
export enum RRuleRepeatFrequency {
  DAILY = 'DAILY',
  MINUTELY = 'MINUTELY',
  MONTHLY = 'MONTHLY',
  SECONDLY = 'SECONDLY',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY'
}

/** Weekday option for ICAL use with byDay and weekStart */
export enum RRuleWeekday {
  FR = 'FR',
  MO = 'MO',
  SA = 'SA',
  SU = 'SU',
  TH = 'TH',
  TU = 'TU',
  WE = 'WE'
}

export interface RelativeDistanceEvent extends BaseEvent {
  __typename?: 'RelativeDistanceEvent';
  content: Array<EventContent>;
  description: Scalars['String']['output'];
  distance: Scalars['PositiveInt']['output'];
  duration: Scalars['NonNegativeInt']['output'];
  id: Scalars['ID']['output'];
  relativeTo: RelativeTo;
  startTime: Scalars['LocalTime']['output'];
  /** Title of event, appears as summary in ical event */
  title: Scalars['String']['output'];
  unit: TimeDistanceUnit;
  variableDefinitions: Array<VariableDefinition>;
}

export interface RelativeDistanceEventOverride {
  duration?: InputMaybe<Scalars['PositiveInt']['input']>;
  id: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
  startTime?: InputMaybe<Scalars['LocalTime']['input']>;
}

/** Relative to option for event interval */
export enum RelativeTo {
  PreviousEventOrProgramStart = 'PreviousEventOrProgramStart',
  ProgramStart = 'ProgramStart'
}

export interface RepeatConfig {
  __typename?: 'RepeatConfig';
  byMonth?: Maybe<Array<Scalars['PositiveInt']['output']>>;
  byMonthDay?: Maybe<Array<Scalars['PositiveInt']['output']>>;
  /**
   * If given, it must be an integer,
   * Each given integer will specify an occurrence number,
   * corresponding to the nth occurrence of the rule inside the frequency period
   * For example, a bySetPos of -1 if combined with a Monthly frequency,
   * and a byWeekday of Mo, Tu, We, Th, Fri,
   * will result in the last work day of every month
   * 1 would result in the first weekday of evey month
   * after the initial event date
   */
  bySetPos?: Maybe<Scalars['Int']['output']>;
  /** When given, these variables will define the weekdays where the recurrence will be applied. */
  byWeekDay?: Maybe<Array<RRuleWeekday>>;
  /** Limit how many occurrences will be generated */
  count?: Maybe<Scalars['PositiveInt']['output']>;
  frequency: RRuleRepeatFrequency;
  /**
   * The interval between each frequency iteration.
   * For example when using a yearly frequency,
   * an interval of 2 mean once every two years.
   */
  interval?: Maybe<Scalars['PositiveInt']['output']>;
  maxWeekDaySelections?: Maybe<Scalars['PositiveInt']['output']>;
  weekStart?: Maybe<RRuleWeekday>;
}

export interface RepeatedTrack extends BaseTrack {
  __typename?: 'RepeatedTrack';
  content: Array<PlaylistTrackContentBlock>;
  hooks?: Maybe<Array<UpdateStateTrackHook>>;
  id: Scalars['ID']['output'];
  logicRule?: Maybe<LogicRule>;
  sets: Array<Set>;
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  variableConfigurations?: Maybe<VariableConfiguration>;
  variableDefinitions?: Maybe<Array<VariableDefinition>>;
}

export interface RepeatingEvent extends BaseEvent {
  __typename?: 'RepeatingEvent';
  content: Array<EventContent>;
  dayRestriction?: Maybe<DayRestriction>;
  daysBetweenEvents?: Maybe<Scalars['Float']['output']>;
  description: Scalars['String']['output'];
  duration: Scalars['NonNegativeInt']['output'];
  id: Scalars['ID']['output'];
  repeating: RepeatConfig;
  startTime: Scalars['LocalTime']['output'];
  /** Title of event, appears as summary in ical event */
  title: Scalars['String']['output'];
  variableDefinitions: Array<VariableDefinition>;
}

export interface RepeatingEventOverride {
  id: Scalars['ID']['input'];
  repeating?: InputMaybe<RepeatingOverrideConfig>;
  startTime?: InputMaybe<Scalars['LocalTime']['input']>;
}

export interface RepeatingOverrideConfig {
  /** When given, these variables will define the weekdays where the recurrence will be applied. */
  byWeekDay?: InputMaybe<Array<RRuleWeekday>>;
}

export interface Repetition {
  __typename?: 'Repetition';
  repeat: CNumber;
  timers: Array<Timer>;
}

export interface RequestMagicLinkToken {
  email: Scalars['EmailAddress']['input'];
}

export interface SessionLog extends CoreLog {
  __typename?: 'SessionLog';
  activityLogs: Array<ActivityLog>;
  currentState: Array<StateData>;
  duration?: Maybe<Scalars['ISO8601Duration']['output']>;
  endDateTime?: Maybe<Scalars['String']['output']>;
  eventId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  programSubscriptionId: Scalars['String']['output'];
  startDateTime: Scalars['String']['output'];
  type: UserProgramSubscriptionLogType;
}

export interface SessionLogInput {
  activityLogs: Array<ActivityLogInput>;
  eventId: Scalars['String']['input'];
  programSubscriptionId: Scalars['String']['input'];
  startDateTime: Scalars['String']['input'];
}

export interface Set {
  __typename?: 'Set';
  repeat: CNumber;
  reps: Array<Repetition>;
  timers: Array<Timer>;
}

export interface SignIn {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface SignInResponse {
  __typename?: 'SignInResponse';
  /** Subscription redirect will be present when the user has one ore more subscriptions */
  subscriptionsRedirect?: Maybe<SubscriptionsRedirect>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
}

export interface SignInViaToken {
  token: Scalars['String']['input'];
}

/** The simple block only has the a single 'Type' field */
export interface SimpleContentBlock extends BaseContentBlock {
  __typename?: 'SimpleContentBlock';
  logicRule?: Maybe<LogicRule>;
  type: ContentBlockType;
}

export interface SliderFormFieldDefinition {
  __typename?: 'SliderFormFieldDefinition';
  key: Scalars['String']['output'];
  templateOptions: SliderFormTemplateOptions;
  type: FormFieldType;
}

export interface SliderFormTemplateOptions {
  __typename?: 'SliderFormTemplateOptions';
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  placeholder?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  step: Scalars['Float']['output'];
}

/** SoundPosition */
export enum SoundPosition {
  EndOfTrack = 'EndOfTrack',
  StartOfTrack = 'StartOfTrack'
}

export type StateData = NumberState | StringState;

export interface StringState {
  __typename?: 'StringState';
  key: Scalars['String']['output'];
  namespace: VariableNamespace;
  /** ID for namespace, i.e program-143 (program namespace), event-324 (event namespace), track-343 (track namespace) */
  namespaceId: Scalars['String']['output'];
  stringValue: Scalars['String']['output'];
}

export interface StringStateInput {
  key: Scalars['String']['input'];
  namespace: VariableNamespace;
  /** ID for namespace, i.e program-143 (program namespace), event-324 (event namespace), track-343 (track namespace) */
  namespaceId: Scalars['String']['input'];
  stringValue: Scalars['String']['input'];
}

export interface StringValue {
  __typename?: 'StringValue';
  stringValue: Scalars['String']['output'];
}

export interface StringVariableDefinition {
  __typename?: 'StringVariableDefinition';
  defaultString: CString;
  key: Scalars['String']['output'];
  userEditable?: Maybe<Scalars['Boolean']['output']>;
}

export interface SubscribeToProgramInput {
  email: Scalars['EmailAddress']['input'];
  endDate: Scalars['LocalDate']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  relativeDistanceEventOverrides: Array<RelativeDistanceEventOverride>;
  repeatingEventOverrides: Array<RepeatingEventOverride>;
  startDate: Scalars['LocalDate']['input'];
  variables: Scalars['JSONObject']['input'];
}

export interface SubscribeToProgramResult {
  __typename?: 'SubscribeToProgramResult';
  confirmationEmail?: Maybe<Scalars['String']['output']>;
  programSubscriptionId: Scalars['ID']['output'];
}

export interface SubscriptionsRedirect {
  __typename?: 'SubscriptionsRedirect';
  firstSubscriptionId?: Maybe<Scalars['ID']['output']>;
  multipleSubscriptions: Scalars['Boolean']['output'];
}

export interface SystemGeneratedVariables {
  __typename?: 'SystemGeneratedVariables';
  endDate: Scalars['LocalDate']['output'];
  startDate: Scalars['LocalDate']['output'];
}

export interface TimeCommitment {
  __typename?: 'TimeCommitment';
  period: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
}

/** Distance unit (hour, day, week, period etc) to use with event interval */
export enum TimeDistanceUnit {
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
  PercentOfProgramPeriod = 'PercentOfProgramPeriod'
}

export interface TimedTrack extends BaseTrack {
  __typename?: 'TimedTrack';
  content: Array<PlaylistTrackContentBlock>;
  hooks?: Maybe<Array<UpdateStateTrackHook>>;
  id: Scalars['ID']['output'];
  logicRule?: Maybe<LogicRule>;
  thumbnail: Scalars['String']['output'];
  timers: Array<Timer>;
  title: Scalars['String']['output'];
  variableConfigurations?: Maybe<VariableConfiguration>;
  variableDefinitions?: Maybe<Array<VariableDefinition>>;
}

export interface Timer {
  __typename?: 'Timer';
  duration: CNumber;
  message: Scalars['String']['output'];
  triggers?: Maybe<Array<TimerTrigger>>;
}

/** Allows timer to trigger actions. Example MarkingComplete */
export enum TimerTrigger {
  MarkCompleted = 'MarkCompleted'
}

export interface TrackCompletion {
  __typename?: 'TrackCompletion';
  trackId: Scalars['String']['output'];
  trackedState?: Maybe<Array<NumberState>>;
  values: Array<CompletionValue>;
}

export interface TrackCompletionInput {
  trackId: Scalars['String']['input'];
  trackedState?: InputMaybe<Array<NumberStateInput>>;
  values: Array<CompletionValueInput>;
}

export interface TrackHeroTitleBlock extends BaseContentBlock {
  __typename?: 'TrackHeroTitleBlock';
  detailedDescription: Array<HtmlContentBlock>;
  image: Scalars['String']['output'];
  logicRule?: Maybe<LogicRule>;
  title: Scalars['String']['output'];
  type: ContentBlockType;
}

/** Status of track, used with track completion Activity logs and track hooks */
export enum TrackStatus {
  Attempted = 'Attempted',
  Completed = 'Completed',
  Unattempted = 'Unattempted'
}

export interface UpdateNumberVariables {
  __typename?: 'UpdateNumberVariables';
  value: CNumber;
  variableRef: Scalars['String']['output'];
  variation: NumberVariation;
}

export interface UpdateStateTrackHook {
  __typename?: 'UpdateStateTrackHook';
  on: TrackStatus;
  updateNumberVars: Array<UpdateNumberVariables>;
}

export interface User {
  __typename?: 'User';
  created: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['String']['output']>;
  modified: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  roles: Array<Scalars['String']['output']>;
}

/** User program subscription log type */
export enum UserProgramSubscriptionLogType {
  Initialisation = 'Initialisation',
  Session = 'Session'
}

export interface VariableConfiguration {
  __typename?: 'VariableConfiguration';
  mode: VariableConfigurationMode;
  variableRef: Scalars['String']['output'];
}

/** Track (saves variable with activity log), Display (shows variable alongside the track), Edit (allows editing the variable value) */
export enum VariableConfigurationMode {
  DisplayAndEdit = 'DisplayAndEdit',
  DisplayAndTrack = 'DisplayAndTrack',
  DisplayTrackAndEdit = 'DisplayTrackAndEdit',
  OnlyDisplay = 'OnlyDisplay',
  OnlyTrack = 'OnlyTrack'
}

export type VariableDefinition = CustomUnitVariableDefinition | DistanceVariableDefinition | NumberVariableDefinition | StringVariableDefinition | WeightVariableDefinition;

/** Namespace for variables */
export enum VariableNamespace {
  event = 'event',
  program = 'program',
  track = 'track'
}

export interface VariableRef {
  __typename?: 'VariableRef';
  variableRef: Scalars['String']['output'];
}

export interface WeightConst extends NumberValue {
  __typename?: 'WeightConst';
  numberValue: Scalars['Float']['output'];
  weightUnit: WeightUnit;
}

export interface WeightRef {
  __typename?: 'WeightRef';
  refWeightUnit?: Maybe<WeightUnit>;
  variableRef: Scalars['String']['output'];
}

/** Unit for Weight category (lb, kg) */
export enum WeightUnit {
  kg = 'kg',
  lb = 'lb'
}

export interface WeightVariableDefinition {
  __typename?: 'WeightVariableDefinition';
  imperialWeightDefault: CWeight;
  key: Scalars['String']['output'];
  max?: Maybe<CNumber>;
  metricWeightDefault: CWeight;
  min?: Maybe<CNumber>;
  type: NumberType;
  userEditable?: Maybe<Scalars['Boolean']['output']>;
  validWeightUnits?: Maybe<Array<WeightUnit>>;
}
