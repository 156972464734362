import {
  Event,
  RelativeDistanceEvent,
  RelativeDistanceEventOverride,
  RepeatingEvent,
  RepeatingEventOverride,
} from '@carabiner/angular-shared/data-access';
import {
  CError,
  isRelativeDistanceEventType,
  isRepeatingEventType,
  OptionalExceptFor,
} from '@carabiner/common-utils';

export interface RepeatingEventWithOverride {
  index: number;
  event: Partial<RepeatingEvent>;
  override: RepeatingEventOverride;
}

export interface RelativeDistanceEventWithOverride {
  index: number;
  event: Partial<RelativeDistanceEvent>;
  override: RelativeDistanceEventOverride;
}

export type EventWithOverride =
  | RepeatingEventWithOverride
  | RelativeDistanceEventWithOverride;

export function eventToEventWithOverride(
  event: OptionalExceptFor<RelativeDistanceEvent, 'id'>,
  index: number
): RelativeDistanceEventWithOverride;
export function eventToEventWithOverride(
  event: OptionalExceptFor<RepeatingEvent, 'id'>,
  index: number
): RepeatingEventWithOverride;
export function eventToEventWithOverride(
  event: OptionalExceptFor<Event, 'id'>,
  index: number
): EventWithOverride {
  if (isRepeatingEventType(event)) {
    const override: RepeatingEventOverride = {
      id: event.id,
      repeating: {
        byWeekDay: event.repeating?.byWeekDay || [],
      },
      startTime: event.startTime,
    };
    return {
      index,
      event,
      override,
    };
  }

  if (isRelativeDistanceEventType(event)) {
    const override: RelativeDistanceEventOverride = {
      id: event.id,
      startDate: null,
      duration: null,
      startTime: event.startTime,
    };
    return {
      index,
      event,
      override,
    };
  }

  throw new CError(
    '[event-to-event-with-override] invalid event provided',
    event
  );
}
