import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PreviewProgramQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PreviewProgramQuery = { __typename?: 'Query', program: { __typename?: 'Program', id: string, title: string, description: string, heroImage: string, heroSwirl: string, author: { __typename?: 'Author', firstName: string, lastName: string }, timeCommitment: { __typename?: 'TimeCommitment', period: string, unit: string, value: number }, duration: { __typename?: 'DurationConfig', value: string, mode: Types.ProgramDurationMode }, previewProgramContent: Array<{ __typename?: 'ActionButtonContentBlock', type: Types.ContentBlockType, action: Types.ButtonContentBlockActions, innerText: string } | { __typename?: 'HorizontalScrollContentBlock', type: Types.ContentBlockType, content: Array<{ __typename?: 'ImageContentBlock', type: Types.ContentBlockType, src: string }> } | { __typename?: 'HtmlContentBlock', type: Types.ContentBlockType, styles?: string | null, html: string } | { __typename?: 'SimpleContentBlock', type: Types.ContentBlockType }> } };

export const PreviewProgramDocument = gql`
    query previewProgram($id: ID!) {
  program(id: $id) {
    id
    title
    description
    author {
      firstName
      lastName
    }
    timeCommitment {
      period
      unit
      value
    }
    duration {
      value
      mode
    }
    heroImage
    heroSwirl
    previewProgramContent {
      ... on HtmlContentBlock {
        type
        styles
        html
      }
      ... on SimpleContentBlock {
        type
      }
      ... on HorizontalScrollContentBlock {
        type
        content {
          ... on ImageContentBlock {
            type
            src
          }
        }
      }
      ... on ActionButtonContentBlock {
        type
        action
        innerText
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PreviewProgramGQL extends Apollo.Query<PreviewProgramQuery, PreviewProgramQueryVariables> {
    document = PreviewProgramDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }