import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { COMMON_STATUS } from '@carabiner/models';
import { PreviousRouteService } from '@carabiner/angular-shared/core-services';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SignInService {
  private signInStatusSubject$ = new BehaviorSubject(COMMON_STATUS.idle);
  readonly signInStatus$ = this.signInStatusSubject$.asObservable();
  private errorSubject$ = new BehaviorSubject<null | string[]>(null);
  readonly errors$ = this.errorSubject$.asObservable();

  constructor(
    readonly authenticationService: AuthenticationService,
    readonly previousUrlService: PreviousRouteService,
    readonly router: Router
  ) {}

  clearErrors() {
    this.errorSubject$.next(null);
  }

  signIn({ email, password }: { email: string; password: string }): void {
    this.signInStatusSubject$.next(COMMON_STATUS.pending);
    this.errorSubject$.next(null);
    this.#signIn({ email, password });
  }

  signInViaToken(token: string | null): void {
    if (!token) {
      this.errorSubject$.next(['No token provided']);
      return;
    }
    this.#signIn({ token });
  }

  #signIn({ email, password }: { email: string; password: string }): void;
  #signIn({ token }: { token: string }): void;
  #signIn({
    token,
    email,
    password,
  }: {
    token: string;
    email: string;
    password: string;
  }): void {
    const authRequest$ = token
      ? this.authenticationService.signInViaToken({ token })
      : this.authenticationService.signIn({ email, password });

    this.signInStatusSubject$.next(COMMON_STATUS.pending);
    this.errorSubject$.next(null);

    authRequest$
      .pipe(
        switchMap(({ success, subscriptionsRedirect }) => {
          if (
            success &&
            this.previousUrlService.previousUrlIncludes('program-subscription')
          ) {
            return this.previousUrlService.backToPreviousRoute();
          }

          if (
            success &&
            subscriptionsRedirect &&
            subscriptionsRedirect.multipleSubscriptions
          ) {
            return this.router.navigate(['subscriptions']);
          }

          if (
            success &&
            subscriptionsRedirect &&
            subscriptionsRedirect.firstSubscriptionId
          ) {
            return this.router.navigate([
              'program-subscription',
              subscriptionsRedirect.firstSubscriptionId,
            ]);
          }

          if (success) {
            return this.router.navigate(['programs']);
          }

          return of(success);
        })
      )
      .subscribe(
        (success) => {
          this.signInStatusSubject$.next(COMMON_STATUS.resolved);
          const message = token
            ? ['Invalid token']
            : [
                'Check your email and password.',
                'New account? click the confirm link we sent to your email.',
              ];
          if (!success) {
            this.errorSubject$.next(message);
          }
        },
        (err) => {
          this.errorSubject$.next(['Network error please try again.']);
          this.signInStatusSubject$.next(COMMON_STATUS.rejected);
        }
      );
  }
}
