import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {
  SignInGQL,
  SignInViaTokenGQL,
  SignOutGQL,
  SubscriptionsRedirect,
} from '@carabiner/angular-shared/data-access';
import { map, shareReplay } from 'rxjs/operators';
import { JWT_DECODE } from './jwt-decode.provider';
import { COMMON_STATUS } from '@carabiner/models';
import { HttpBackend, HttpClient } from '@angular/common/http';

interface StoredToken {
  token: string | null;
  expiry: number | null;
}

interface SignInResult {
  success: boolean;
  subscriptionsRedirect?: SubscriptionsRedirect;
}

const TWENTY_SECONDS = 20_000;
const TOKEN_URI = '/api/refresh-token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  #tokenStore = new BehaviorSubject<StoredToken>({ token: null, expiry: null });
  token$: Observable<string | null> = this.#tokenStore
    .asObservable()
    .pipe(map(({ token }) => token));
  isLoggedIn$ = this.token$.pipe(map(Boolean));

  refreshTokenStatus = new BehaviorSubject(COMMON_STATUS.idle);
  refreshTokenRequest$: null | Observable<string | null> = null;

  httpClient: HttpClient;
  constructor(
    private signInGQL: SignInGQL,
    private signOutGQL: SignOutGQL,
    private signInViaTokenGQL: SignInViaTokenGQL,
    private errorHandler: ErrorHandler,
    private httpBackend: HttpBackend,
    @Inject(JWT_DECODE) private jwtDecode: any
  ) {
    // don't use http interceptors when making the refresh request
    // as the token interceptor depends on this module (infinite loop)
    // constructing this http client with the httpBackend
    // prevents it from using the interceptors

    this.httpClient = new HttpClient(httpBackend);
  }

  getValidTokenFromStore(): string | null {
    const { token, expiry } = this.#tokenStore.getValue();
    if (!expiry) {
      return null;
    }

    const timeNow = Date.now();
    const expiryLess20s = expiry - TWENTY_SECONDS;
    if (timeNow > expiryLess20s) {
      return null;
    }
    return token;
  }

  getToken(): Observable<string | null> {
    const token = this.getValidTokenFromStore();
    if (token !== null) {
      return of(token);
    }
    return this.refreshToken();
  }

  refreshToken(): Observable<string | null> {
    if (this.refreshTokenRequest$ !== null) {
      return this.refreshTokenRequest$;
    }

    this.refreshTokenRequest$ = this.httpClient
      .post<{ ok: boolean; token: string }>(TOKEN_URI, null, {
        withCredentials: true,
      })
      .pipe(
        map(({ token, ok }) => {
          if (ok) {
            this.setToken(token);
          }
          this.refreshTokenRequest$ = null;
          return this.getValidTokenFromStore();
        }),
        shareReplay()
      );

    return this.refreshTokenRequest$;
  }

  signIn({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Observable<SignInResult> {
    return this.signInGQL
      .mutate({
        input: {
          email,
          password,
        },
      })
      .pipe(map((v) => this.#setJWTReturnResult(v?.data?.signIn)));
  }

  signInViaToken({ token }: { token: string }): Observable<SignInResult> {
    return this.signInViaTokenGQL
      .mutate({
        input: {
          token,
        },
      })
      .pipe(map((v) => this.#setJWTReturnResult(v?.data?.signInViaToken)));
  }

  #setJWTReturnResult(
    signIn:
      | {
          success?: boolean;
          token?: string | null;
          subscriptionsRedirect?: SubscriptionsRedirect | null;
        }
      | undefined
  ): SignInResult {
    const success: boolean = signIn?.success || false;
    const token = signIn?.token;
    const subscriptionsRedirect = signIn?.subscriptionsRedirect;
    if (success && token) {
      this.setToken(token);
    }
    return { success, subscriptionsRedirect } as {
      success: boolean;
      subscriptionRedirect?: SubscriptionsRedirect;
    };
  }

  signOut(): Observable<boolean> {
    return this.signOutGQL.mutate().pipe(
      map((result) => {
        const success: boolean = result?.data?.signOut || false;
        if (success) {
          this.clearToken();
        }
        return success;
      })
    );
  }

  setToken(token: string) {
    try {
      const { exp } = <{ exp: number }>this.jwtDecode(token);
      this.#tokenStore.next({ token, expiry: exp * 1000 });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  clearToken() {
    this.#tokenStore.next({ token: null, expiry: null });
  }
}
