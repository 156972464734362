import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { Validators } from '@angular/forms';

export function minlengthValidationMessage(
  err: unknown,
  field: FormlyFieldConfig
) {
  return `Should have at least ${field.templateOptions?.minLength} characters`;
}

export function maxlengthValidationMessage(
  err: unknown,
  field: FormlyFieldConfig
) {
  return `This value should be less than ${field.templateOptions?.maxLength} characters`;
}

export function minValidationMessage(err: unknown, field: FormlyFieldConfig) {
  return `This value should be more than ${field.templateOptions?.min}`;
}

export function maxValidationMessage(err: unknown, field: FormlyFieldConfig) {
  return `This value should be less than ${field.templateOptions?.max}`;
}

export const FormlyRootModule = FormlyModule.forRoot({
  validationMessages: [
    { name: 'required', message: 'This field is required' },
    { name: 'minLength', message: minlengthValidationMessage },
    { name: 'maxLength', message: maxlengthValidationMessage },
    { name: 'min', message: minValidationMessage },
    { name: 'max', message: maxValidationMessage },
    { name: 'email', message: 'Please enter a valid email' },
  ],
  validators: [{ name: 'email', validation: Validators.email }],
});
