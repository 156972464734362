import { Inject, Injectable } from '@angular/core';
import { CONSOLE } from './browser-tokens';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6,
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  #logLevel: LogLevel = LogLevel.Warn;
  #allowableFilterKeys = new Set();

  constructor(@Inject(CONSOLE) private console: Console) {}

  setLogLevel(logLevel: LogLevel) {
    this.#logLevel = logLevel;
  }

  setAllowableFilterKeys(updatedSet: string[]) {
    this.#allowableFilterKeys = new Set(updatedSet);
  }

  debug(message: string, ...optionalParams: any[]) {
    this._log(LogLevel.Debug, message, ...optionalParams);
  }

  fdebug(filterKey: string, message: string, ...optionalParams: any[]) {
    if (this.#allowableFilterKeys.has(filterKey)) {
      this._log(LogLevel.Debug, message, ...optionalParams);
    }
  }

  info(message: string, ...optionalParams: any[]) {
    this._log(LogLevel.Info, message, ...optionalParams);
  }

  warn(message: string, ...optionalParams: any[]) {
    this._log(LogLevel.Warn, message, ...optionalParams);
  }

  error(message: string, ...optionalParams: any[]) {
    this._log(LogLevel.Error, message, ...optionalParams);
  }

  fatal(message: string, ...optionalParams: any[]) {
    this._log(LogLevel.Fatal, message, ...optionalParams);
  }

  log(message: string, ...optionalParams: any[]) {
    this._log(LogLevel.All, message, ...optionalParams);
  }

  _log(level: LogLevel, message: string, ...optionalParams: any[]) {
    if (level >= this.#logLevel) {
      switch (level) {
        case LogLevel.Warn:
          this.console.warn(message, ...optionalParams);
          break;
        case LogLevel.Error:
        case LogLevel.Fatal:
          this.console.error(message, ...optionalParams);
          break;
        case LogLevel.Info:
          // eslint-disable-next-line no-restricted-syntax
          this.console.info(message, ...optionalParams);
          break;
        case LogLevel.Debug:
          this.console.debug(message, ...optionalParams);
          break;
        default:
          this.console.log(message, ...optionalParams);
      }
    }
  }
}
