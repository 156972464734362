import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { StateDataFragmentDoc } from '../state/state-data.fragment.generated';
import { SessionLogFragmentDoc } from '../session-log/session-log.fragment.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LogCurrentUserSessionMutationVariables = Types.Exact<{
  input: Types.SessionLogInput;
}>;


export type LogCurrentUserSessionMutation = { __typename?: 'Mutation', logCurrentUserSession: { __typename?: 'LogCurrentUserSessionResponse', sessionLog: { __typename?: 'SessionLog', id: string, startDateTime: string, endDateTime?: string | null, duration?: string | null, eventId: string, currentState: Array<{ __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null } | { __typename?: 'StringState', key: string, namespace: Types.VariableNamespace, namespaceId: string, stringValue: string }>, activityLogs: Array<{ __typename?: 'ActivityLog', trackCompletion?: { __typename?: 'TrackCompletion', trackId: string, values: Array<{ __typename?: 'CompletionValue', status: Types.TrackStatus, numberValue: number, setIndex?: number | null, setBlockIndex?: number | null }>, trackedState?: Array<{ __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null }> | null } | null }> } } };

export const LogCurrentUserSessionDocument = gql`
    mutation logCurrentUserSession($input: SessionLogInput!) {
  logCurrentUserSession(input: $input) {
    sessionLog {
      currentState {
        ...StateData
      }
      ...SessionLog
    }
  }
}
    ${StateDataFragmentDoc}
${SessionLogFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LogCurrentUserSessionGQL extends Apollo.Mutation<LogCurrentUserSessionMutation, LogCurrentUserSessionMutationVariables> {
    document = LogCurrentUserSessionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }