import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RepeatSectionFormlyComponent } from './repeat-section.type.component';
import { FormlyModule } from '@ngx-formly/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [
    RepeatSectionFormlyComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'repeat-section',
          component: RepeatSectionFormlyComponent,
        }
      ]
    })
  ]
})
export class RepeatSectionFormlyModule {}
