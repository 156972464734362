import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { StateDataFragmentDoc } from '../state/state-data.fragment.generated';
export type CurrentStateFragment = { __typename?: 'CurrentUserState', system: { __typename?: 'SystemGeneratedVariables', startDate: string, endDate: string }, state: Array<{ __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null } | { __typename?: 'StringState', key: string, namespace: Types.VariableNamespace, namespaceId: string, stringValue: string }>, schedule: Array<{ __typename?: 'Appointment', date: string, eventId: string, startTime: string }> };

export const CurrentStateFragmentDoc = gql`
    fragment CurrentState on CurrentUserState {
  system {
    startDate
    endDate
  }
  state {
    ...StateData
  }
  schedule {
    date
    eventId
    startTime
  }
}
    ${StateDataFragmentDoc}`;