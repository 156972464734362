// extracts the date irrespective of offset
// so the local date YYYY-MM-DD
import { leftPad } from './left-pad';

export function dateToLocalDateString(date: Date) {
  // date month starts a 0
  const month: string = (date.getMonth() + 1).toString(10)
  const day: string = date.getDate().toString(10)

  return `${date.getFullYear()}-${pad(month)}-${pad(day)}`
}

function pad(value: string): string {
  return leftPad(value, 2, 0)
}
