import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
export type PlaylistTrackContent_ActionButtonContentBlock_Fragment = { __typename: 'ActionButtonContentBlock', type: Types.ContentBlockType, action: Types.ButtonContentBlockActions, innerText: string };

export type PlaylistTrackContent_AudioContentBlock_Fragment = { __typename: 'AudioContentBlock', type: Types.ContentBlockType, fileSrc: string, soundPosition?: Types.SoundPosition | null, soundPositionOffset?: number | null };

export type PlaylistTrackContent_HtmlContentBlock_Fragment = { __typename: 'HtmlContentBlock', type: Types.ContentBlockType, html: string, styles?: string | null, includeInPreview?: boolean | null };

export type PlaylistTrackContent_SimpleContentBlock_Fragment = { __typename: 'SimpleContentBlock', type: Types.ContentBlockType };

export type PlaylistTrackContent_TrackHeroTitleBlock_Fragment = { __typename: 'TrackHeroTitleBlock', type: Types.ContentBlockType, image: string, title: string, detailedDescription: Array<{ __typename?: 'HtmlContentBlock', html: string }> };

export type PlaylistTrackContentFragment = PlaylistTrackContent_ActionButtonContentBlock_Fragment | PlaylistTrackContent_AudioContentBlock_Fragment | PlaylistTrackContent_HtmlContentBlock_Fragment | PlaylistTrackContent_SimpleContentBlock_Fragment | PlaylistTrackContent_TrackHeroTitleBlock_Fragment;

export const PlaylistTrackContentFragmentDoc = gql`
    fragment PlaylistTrackContent on PlaylistTrackContentBlock {
  __typename
  ... on BaseContentBlock {
    __typename
    type
  }
  ... on HtmlContentBlock {
    __typename
    html
    styles
    includeInPreview
  }
  ... on ActionButtonContentBlock {
    __typename
    action
    innerText
  }
  ... on AudioContentBlock {
    __typename
    fileSrc
    soundPosition
    soundPositionOffset
  }
  ... on TrackHeroTitleBlock {
    __typename
    image
    title
    detailedDescription {
      html
    }
  }
}
    `;