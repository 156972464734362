import { UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import {
  DayRestriction,
  RepeatingEvent,
  RepeatingEventOverride,
  RRuleWeekday,
} from '@carabiner/angular-shared/data-access';
import { equals, head, isNil, mergeDeepRight, pathOr } from 'ramda';
import { FormSetup, FormSetupParams } from './core-types';
import { RepeatingEventWithOverride } from '../event-to-event-with-override';
import { dateToRruleWeekday } from '@carabiner/common-utils';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { LoggerService } from '@carabiner/angular-shared/core-services';
import { combineLatest } from 'rxjs';

export interface RepeatingEventFormModel {
  days: string[];
  startTime: string;
  duration: number;
}

type RepeatingEventFormSetupParams = Omit<FormSetupParams, 'ewo'> & {
  ewo: RepeatingEventWithOverride;
};

export function repeatingEventFormConfig({
  ewo,
  destroy$,
  eventsFormArray,
  startAndEndDate$,
  logger,
}: RepeatingEventFormSetupParams): FormSetup {
  let patchValue = null;
  const startDateDayRestriction =
    ewo?.event?.dayRestriction === DayRestriction.StartDate;

  const afterPreviousBeforeFirst =
    ewo?.event.dayRestriction === DayRestriction.AfterPreviousBeforeFirst;

  const disabledMessage = 'This day is set by the program start date';

  let availableDays = undefined;
  const isFirstEvent = ewo.index === 0;

  if (afterPreviousBeforeFirst && eventsFormArray && !isFirstEvent) {
    const initialFirstEvent = eventsFormArray.at(0)?.value as object;
    const firstEventDay$ = eventsFormArray.at(0).valueChanges.pipe(
      startWith(initialFirstEvent),
      map((v) => head(v?.override?.repeating?.byWeekDay)),
      shareReplay(1)
    );

    const previousDayIndex = ewo.index - 1;
    const initialPreviousEvent = eventsFormArray.at(previousDayIndex)
      ?.value as any;
    const previousEventDay$ = eventsFormArray
      .at(previousDayIndex)
      .valueChanges.pipe(
        startWith(initialPreviousEvent),
        map((v) => head(v?.override?.repeating?.byWeekDay)),
        shareReplay(1)
      );

    availableDays = combineLatest([firstEventDay$, previousEventDay$]).pipe(
      distinctUntilChanged(equals),
      map(([firstDay, previousDay]: any) => {
        return getAvailableAfterPreviousBeforeFirstRRuleDays(
          {
            firstDay,
            previousDay,
            distanceBetween: ewo.event.daysBetweenEvents || 0,
            totalDays: eventsFormArray.length,
            currentDay: ewo.index + 1,
          },
          logger
        );
      })
    );
  }

  const fields = [
    {
      key: 'days',
      type: 'day-picker',
      templateOptions: {
        label: 'Day of week',
        maxDaySelections: 1,
        required: true,
        availableDays,
        disabled: startDateDayRestriction,
        ...(startDateDayRestriction ? { disabledMessage } : null),
      },
      validation: {
        messages: {
          required: 'You must select a day',
        },
      },
    },
    {
      type: 'flex-group',
      fieldGroup: [
        {
          key: 'startTime',
          type: 'time-picker',
          templateOptions: {
            label: 'Start time',
          },
        },
        {
          key: 'duration',
          type: 'duration-to-end-time',
          templateOptions: {},
        },
      ],
    },
  ];

  const form = new UntypedFormGroup({
    days: new UntypedFormControl(),
    startTime: new UntypedFormControl(),
    duration: new UntypedFormControl(), // readonly but needed
  });

  if (startDateDayRestriction) {
    startAndEndDate$.subscribe(({ programStart }) => {
      const selectedWeekday = dateToRruleWeekday(programStart);
      const pv = { days: [selectedWeekday] };
      form.patchValue(pv);
      patchValue = pv;
    });
  }

  return {
    fields,
    form,
    patchValue,
  };
}

export function repeatingEventToFormModelAndDetailBlock(
  ewo: RepeatingEventWithOverride
): { details: string[]; model: RepeatingEventFormModel } {
  return {
    model: repeatingEventToFormModel(ewo),
    details: [createRepeatingText(ewo.event)],
  };
}

function repeatingEventToFormModel({
  event,
  override,
}: RepeatingEventWithOverride): RepeatingEventFormModel {
  return {
    days: pathOr([], ['repeating', 'byWeekDay'], override),
    startTime: pathOr('', ['startTime'], override),
    duration: pathOr(0, ['duration'], event),
  };
}

export function createRepeatingText(event: Partial<RepeatingEvent>): string {
  const frequency = pathOr(
    '',
    ['repeating', 'frequency'],
    event
  ).toLocaleLowerCase();
  return `Repeating ${frequency}`;
}

export function repeatingEventModelToEventWithOverride({
  ewoOriginal,
  model,
}: {
  ewoOriginal: RepeatingEventWithOverride;
  model: RepeatingEventFormModel;
}): RepeatingEventWithOverride {
  const { days, startTime } = model;
  const override: RepeatingEventOverride = Object.assign(
    { ...ewoOriginal.override },
    days ? { repeating: { byWeekDay: days } } : null,
    startTime ? { startTime } : null
  );

  return mergeDeepRight(ewoOriginal, {
    override,
  }) as RepeatingEventWithOverride;
}

const rruleDaysGenerator = (): RRuleWeekday[] => [
  RRuleWeekday.MO,
  RRuleWeekday.TU,
  RRuleWeekday.WE,
  RRuleWeekday.TH,
  RRuleWeekday.FR,
  RRuleWeekday.SA,
  RRuleWeekday.SU,
];

interface GetAvailableRRuleDaysParams {
  firstDay?: RRuleWeekday;
  previousDay?: RRuleWeekday;
  currentDay: number;
  totalDays: number;
  distanceBetween: number;
}

const VALID_TOTAL_DAYS_AND_DISTANCE_BETWEEN_MAX: { [key: number]: number } = {
  // total days / distance between max
  7: 0,
  6: 0,
  5: 0,
  4: 0,
  3: 1,
  2: 2,
  1: 6, // no point
};

export function getAvailableAfterPreviousBeforeFirstRRuleDays(
  {
    firstDay,
    previousDay,
    currentDay, // day 1, day 2, day 3
    totalDays,
    distanceBetween,
  }: GetAvailableRRuleDaysParams,
  logger: LoggerService
) {
  if (distanceBetween > VALID_TOTAL_DAYS_AND_DISTANCE_BETWEEN_MAX[totalDays]) {
    logger.error(
      `Invalid Config. Distance between days ${distanceBetween} exceed the maximum for ${totalDays}, fall back to all days`
    );
    return [...rruleDaysGenerator()];
  }

  const rruleDays = [...rruleDaysGenerator()];
  if (isNil(firstDay) || currentDay === 1) {
    return rruleDays;
  }
  const index = rruleDays.findIndex((item) => item === firstDay);
  const ahead = rruleDays.slice(index + 1, 7);
  const atail = rruleDays.slice(0, index);
  const daysAfterStartDayInOrder = [...ahead, ...atail];

  const { minimumStartIndex, endIndex } = getDayIndexes(
    currentDay,
    distanceBetween,
    totalDays
  );

  const distance = 1 + distanceBetween;
  const previousDayStartIndex = previousDay
    ? daysAfterStartDayInOrder.findIndex((item) => item === previousDay) +
      distance
    : minimumStartIndex;

  // use previous day index as long as it meets the minimum start index
  // and is less than the end index
  const startIndex =
    previousDayStartIndex > minimumStartIndex &&
    endIndex > previousDayStartIndex
      ? previousDayStartIndex
      : minimumStartIndex;

  return daysAfterStartDayInOrder.slice(startIndex, endIndex);
}

function getDayIndexes(
  currentDay: number,
  distanceBetween: number,
  totalDays: number
) {
  if (distanceBetween === 0) {
    return zeroDistanceIndex(currentDay, totalDays);
  }
  if (distanceBetween === 1) {
    return oneDayGapIndex(currentDay, totalDays);
  }

  // the only valid value when distance between is two
  return { minimumStartIndex: 2, endIndex: 3 };
}

function zeroDistanceIndex(currentDay: number, totalDays: number) {
  // [Mo], Tu, We, Th, Fr, Sa, Su
  // totalDays = 7
  // currentDay = 2
  const maxEndDate = 6 + (2 - totalDays);
  return {
    // only six items left arrays are zero indexed
    // so current day - 2 is the start of the array
    minimumStartIndex: currentDay - 2,
    endIndex: maxEndDate,
  };
}

function oneDayGapIndex(currentDay: number, totalDays: number) {
  // will only ever be 2 or 3 total days for a 1 day gap
  // if 2 days the index is just a one day gap either side of selected day
  // Mo | Tu, *We, *Th, *Fr, *Sa, Su
  if (totalDays === 2) {
    return {
      minimumStartIndex: 1,
      endIndex: 5,
    };
  }

  if (currentDay === 2) {
    // Monday is already removed from array so we just have Tu -> Su
    // Mo | Tu, *We, *Th, Fr, Sa, Su
    return {
      // only six items left arrays are zero indexed
      minimumStartIndex: 1,
      // can only be We, Th as the final day must be Saturday
      endIndex: 3,
    };
  }
  // final option current day === 3
  // Mo | Tu, *We, Th, Fr, Sa, Su
  // assuming Wednesday was picked Fr, Sa would be available
  return {
    minimumStartIndex: 3,
    endIndex: 5,
  };
}
