import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
export type CNumber_NumberConst_Fragment = { __typename: 'NumberConst', numberValue: number };

export type CNumber_VariableRef_Fragment = { __typename: 'VariableRef', variableRef: string };

export type CNumberFragment = CNumber_NumberConst_Fragment | CNumber_VariableRef_Fragment;

export type CString_StringValue_Fragment = { __typename: 'StringValue', stringValue: string };

export type CString_VariableRef_Fragment = { __typename: 'VariableRef', variableRef: string };

export type CStringFragment = CString_StringValue_Fragment | CString_VariableRef_Fragment;

export type CWeight_WeightConst_Fragment = { __typename: 'WeightConst', numberValue: number, weightUnit: Types.WeightUnit };

export type CWeight_WeightRef_Fragment = { __typename: 'WeightRef', variableRef: string, refWeightUnit?: Types.WeightUnit | null };

export type CWeightFragment = CWeight_WeightConst_Fragment | CWeight_WeightRef_Fragment;

export type CDistance_DistanceConst_Fragment = { __typename: 'DistanceConst', numberValue: number, distanceUnit: Types.DistanceUnit };

export type CDistance_DistanceRef_Fragment = { __typename: 'DistanceRef', variableRef: string, refDistanceUnit?: Types.DistanceUnit | null };

export type CDistanceFragment = CDistance_DistanceConst_Fragment | CDistance_DistanceRef_Fragment;

export type CCustom_CustomUnitConst_Fragment = { __typename: 'CustomUnitConst', customUnit: string, numberValue: number };

export type CCustom_CustomUnitRef_Fragment = { __typename: 'CustomUnitRef', customUnit: string, variableRef: string };

export type CCustomFragment = CCustom_CustomUnitConst_Fragment | CCustom_CustomUnitRef_Fragment;

export const CNumberFragmentDoc = gql`
    fragment CNumber on CNumber {
  __typename
  ... on NumberConst {
    numberValue
  }
  ... on VariableRef {
    variableRef
  }
}
    `;
export const CStringFragmentDoc = gql`
    fragment CString on CString {
  __typename
  ... on StringValue {
    stringValue
  }
  ... on VariableRef {
    variableRef
  }
}
    `;
export const CWeightFragmentDoc = gql`
    fragment CWeight on CWeight {
  __typename
  ... on WeightConst {
    numberValue
    weightUnit
  }
  ... on WeightRef {
    variableRef
    refWeightUnit
  }
}
    `;
export const CDistanceFragmentDoc = gql`
    fragment CDistance on CDistance {
  __typename
  ... on DistanceConst {
    numberValue
    distanceUnit
  }
  ... on DistanceRef {
    variableRef
    refDistanceUnit
  }
}
    `;
export const CCustomFragmentDoc = gql`
    fragment CCustom on CCustomUnit {
  __typename
  ... on CustomUnitConst {
    customUnit
    numberValue
  }
  ... on CustomUnitRef {
    customUnit
    variableRef
  }
}
    `;