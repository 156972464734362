import { ISOWeekDays, RRuleWeekday } from '@carabiner/models';

export const RRuleToISOWeekday: { [key in RRuleWeekday]: ISOWeekDays } = {
  [RRuleWeekday.MO]: 'MONDAY',
  [RRuleWeekday.TU]: 'TUESDAY',
  [RRuleWeekday.WE]: 'WEDNESDAY',
  [RRuleWeekday.TH]: 'THURSDAY',
  [RRuleWeekday.FR]: 'FRIDAY',
  [RRuleWeekday.SA]: 'SATURDAY',
  [RRuleWeekday.SU]: 'SUNDAY',
};

export const ISOWeekdayToRRuleWeekday: { [key in ISOWeekDays]: RRuleWeekday } =
  {
    MONDAY: RRuleWeekday.MO,
    TUESDAY: RRuleWeekday.TU,
    WEDNESDAY: RRuleWeekday.WE,
    THURSDAY: RRuleWeekday.TH,
    FRIDAY: RRuleWeekday.FR,
    SATURDAY: RRuleWeekday.SA,
    SUNDAY: RRuleWeekday.SU,
  };
