import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  forwardRef,
  ChangeDetectorRef,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Event } from '@carabiner/angular-shared/data-access';
import { clone, equals, isEmpty } from 'ramda';
import { OnDestroyService } from '@carabiner/angular-shared/ui';
import {
  eventWithOverridesToFormModelAndDetailBlocks,
  formModelToEvent,
  getTypeName,
  setupEventForm,
} from './event-form-control-utils';
import { merge, Subject } from 'rxjs';
import { EventWithOverride } from '../event-to-event-with-override';
import { ProgramStartAndEnd } from '../program-start-and-end';
import { FormSetupParams } from './core-types';
import { LoggerService } from '@carabiner/angular-shared/core-services';

@Component({
  selector: 'carabiner-event-form-control',
  templateUrl: './event-form-control.component.html',
  styleUrls: ['./event-form-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EventFormControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: EventFormControlComponent,
      multi: true,
    },
    OnDestroyService,
  ],
})
export class EventFormControlComponent
  implements ControlValueAccessor, Validator, OnDestroy
{
  destroy$ = new Subject();

  @Input()
  startAndEndDate$!: ProgramStartAndEnd;

  @Input()
  eventsFormArray: UntypedFormArray | null = null;

  form = new UntypedFormGroup({});
  model = {};
  fields: FormlyFieldConfig[] = [];

  get emptyFields(): boolean {
    return this.fields.length === 0;
  }

  currentEwo: EventWithOverride | null = null;
  get event(): Partial<Event> {
    return this?.currentEwo?.event || {};
  }

  details: string[] = [];

  constructor(private logger: LoggerService, private cdr: ChangeDetectorRef) {}

  // we have this subject as the form may be overwritten when writing a value
  formValueChangesSubject$ = new Subject();
  formValueChanges$ = this.formValueChangesSubject$
    .asObservable()
    .pipe(takeUntil(this.destroy$));

  newFormSetup$ = new Subject();
  formSubscriptionsDestroy$ = merge(this.destroy$, this.newFormSetup$);

  writeValue(ewo: EventWithOverride | null) {
    let patchValue = null;
    if (!ewo) {
      this.form = new UntypedFormGroup({});
      this.currentEwo = null;
      this.model = {};
      this.fields = [];
      this.cdr.markForCheck();
      return;
    }

    // new type create new form
    if (getTypeName(ewo) !== getTypeName(this.currentEwo)) {
      // reset any existing form
      this.newFormSetup$.next();
      const formSetupParams: FormSetupParams = {
        ewo,
        destroy$: this.formSubscriptionsDestroy$,
        startAndEndDate$: this.startAndEndDate$,
        eventsFormArray: this.eventsFormArray,
        logger: this.logger,
      };
      const { fields, form, patchValue: pv } = setupEventForm(formSetupParams);
      this.fields = fields;
      this.form = form;
      this.model = {};
      patchValue = pv;

      this.form.valueChanges
        .pipe(takeUntil(this.formSubscriptionsDestroy$))
        .subscribe((v) => this.formValueChangesSubject$.next(v));
    }

    const { model, details } =
      eventWithOverridesToFormModelAndDetailBlocks(ewo);
    this.currentEwo = ewo;
    this.details = details;

    const updatedModel = {
      ...model,
      ...(patchValue && patchValue),
    };

    if (isEmpty(this.model)) {
      this.model = clone(updatedModel);
    }

    this.form.patchValue(clone(updatedModel), { emitEvent: false });
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any) {
    this.formValueChanges$
      .pipe(
        // formValueChanges does not return disabled control values
        // and thus the wrong value is emitted
        // rawValue includes the value set from the disabled control
        // so we want to preference the value, but fallback to rawValue
        map((e: any) => ({ ...this.form.getRawValue(), ...e })),
        distinctUntilChanged(equals),
        map(formModelToEvent(this.currentEwo)),
        takeUntil(this.destroy$)
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.formValueChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => fn());
  }

  validate() {
    if (!this.form.valid) {
      return {
        event: 'event invalid',
      };
    }
    return null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
