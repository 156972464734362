import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ProgramStartAndEnd, startAndEndDate } from '../program-start-and-end';
import { UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'carabiner-event-formly-type',
  templateUrl: './event.type.component.html',
  styleUrls: ['./event.type.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormlyTypeComponent extends FieldType implements OnInit {
  startAndEndDate$?: ProgramStartAndEnd;
  eventsFormArray: null | UntypedFormArray = null;
  ngOnInit() {
    const startDate$ = this?.to?.startProgram$;
    const endDate$ = this?.to?.endProgram$;

    if (!startDate$ || !endDate$) {
      throw new Error(
        'You must provide a start and end date for the formly event type'
      );
    }

    if (this?.field?.parent?.formControl instanceof UntypedFormArray) {
      this.eventsFormArray = this!.field!.parent!.formControl;
    }

    this.startAndEndDate$ = startAndEndDate(startDate$, endDate$);
  }
}
