import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: 'carabiner-time-picker-formly-type',
  templateUrl: './time-picker.type.component.html',
  styleUrls: ['./time-picker.type.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePickerFormlyTypeComponent extends FieldType {}
