import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { BehaviorSubject } from 'rxjs';
import {
  COMMON_STATUS,
  isPending,
  isRejected,
  isResolved,
} from '@carabiner/models';
import { map } from 'rxjs/operators';

@Component({
  selector: 'carabiner-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignOutComponent implements OnInit {
  status$ = new BehaviorSubject<COMMON_STATUS>(COMMON_STATUS.idle);
  errors$ = new BehaviorSubject<null | string[]>(null);
  pending$ = this.status$.pipe(map(isPending));
  success$ = this.status$.pipe(map(isResolved));
  rejected$ = this.status$.pipe(map(isRejected));

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.signOut();
  }

  signOut() {
    this.status$.next(COMMON_STATUS.pending);
    this.authenticationService.signOut().subscribe(
      () => {
        this.status$.next(COMMON_STATUS.resolved);
      },
      () => {
        this.status$.next(COMMON_STATUS.rejected);
        this.errors$.next([
          'Network error failed to sign out. Please try again',
        ]);
      }
    );
  }
}
