import { ConfigOption } from "@ngx-formly/core";
import { DurationToEndTimeTypeComponent } from "./duration-to-end-time.type.component";

export const DurationToEndTimeConfig: ConfigOption = {
  types: [
    {
      name: "duration-to-end-time",
      component: DurationToEndTimeTypeComponent
    }
  ]
};
