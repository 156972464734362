import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSuffixComponent } from './mat-suffix.component';
import { MatIconModule } from "@angular/material/icon";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";

const EXTENSION_NAME = 'mat-suffix'

function onPopulate(field: FormlyFieldConfig) {
  if (!field.templateOptions || (field.wrappers && field.wrappers.indexOf(EXTENSION_NAME) !== -1)) {
    return;
  }

  if (field.templateOptions.matSuffix) {
    field.wrappers = [...(field.wrappers || []), EXTENSION_NAME];
  }
}

const SUFFIX_EXTENSION_CONFIG = {
  wrappers: [
    { name: EXTENSION_NAME, component: MatSuffixComponent}
  ],
  extensions: [
    {
      name: 'mat-suffix',
      extension: {
        onPopulate
      }
    }
  ]
}

@NgModule({
  declarations: [
    MatSuffixComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FormlyModule.forChild(SUFFIX_EXTENSION_CONFIG)
  ],
  exports: [MatSuffixComponent]
})
export class MatSuffixModule { }
