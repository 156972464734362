import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { CNumberFragmentDoc, CWeightFragmentDoc, CDistanceFragmentDoc, CCustomFragmentDoc, CStringFragmentDoc } from './cvalues.fragment.generated';
export type VariableDefinitions_CustomUnitVariableDefinition_Fragment = { __typename: 'CustomUnitVariableDefinition', key: string, type: Types.NumberType, validCustomUnits?: Array<string> | null, userEditable?: boolean | null, min?: { __typename: 'NumberConst', numberValue: number } | { __typename: 'VariableRef', variableRef: string } | null, max?: { __typename: 'NumberConst', numberValue: number } | { __typename: 'VariableRef', variableRef: string } | null, defaultCustomUnitValue: { __typename: 'CustomUnitConst', customUnit: string, numberValue: number } | { __typename: 'CustomUnitRef', customUnit: string, variableRef: string } };

export type VariableDefinitions_DistanceVariableDefinition_Fragment = { __typename: 'DistanceVariableDefinition', key: string, type: Types.NumberType, validDistanceUnits?: Array<Types.DistanceUnit> | null, userEditable?: boolean | null, min?: { __typename: 'NumberConst', numberValue: number } | { __typename: 'VariableRef', variableRef: string } | null, max?: { __typename: 'NumberConst', numberValue: number } | { __typename: 'VariableRef', variableRef: string } | null, imperialDistanceDefault: { __typename: 'DistanceConst', numberValue: number, distanceUnit: Types.DistanceUnit } | { __typename: 'DistanceRef', variableRef: string, refDistanceUnit?: Types.DistanceUnit | null }, metricDistanceDefault: { __typename: 'DistanceConst', numberValue: number, distanceUnit: Types.DistanceUnit } | { __typename: 'DistanceRef', variableRef: string, refDistanceUnit?: Types.DistanceUnit | null } };

export type VariableDefinitions_NumberVariableDefinition_Fragment = { __typename: 'NumberVariableDefinition', key: string, type: Types.NumberType, userEditable?: boolean | null, min?: { __typename: 'NumberConst', numberValue: number } | { __typename: 'VariableRef', variableRef: string } | null, max?: { __typename: 'NumberConst', numberValue: number } | { __typename: 'VariableRef', variableRef: string } | null, defaultNumber: { __typename: 'NumberConst', numberValue: number } | { __typename: 'VariableRef', variableRef: string } };

export type VariableDefinitions_StringVariableDefinition_Fragment = { __typename: 'StringVariableDefinition', key: string, userEditable?: boolean | null, defaultString: { __typename: 'StringValue', stringValue: string } | { __typename: 'VariableRef', variableRef: string } };

export type VariableDefinitions_WeightVariableDefinition_Fragment = { __typename: 'WeightVariableDefinition', key: string, type: Types.NumberType, validWeightUnits?: Array<Types.WeightUnit> | null, userEditable?: boolean | null, min?: { __typename: 'NumberConst', numberValue: number } | { __typename: 'VariableRef', variableRef: string } | null, max?: { __typename: 'NumberConst', numberValue: number } | { __typename: 'VariableRef', variableRef: string } | null, imperialWeightDefault: { __typename: 'WeightConst', numberValue: number, weightUnit: Types.WeightUnit } | { __typename: 'WeightRef', variableRef: string, refWeightUnit?: Types.WeightUnit | null }, metricWeightDefault: { __typename: 'WeightConst', numberValue: number, weightUnit: Types.WeightUnit } | { __typename: 'WeightRef', variableRef: string, refWeightUnit?: Types.WeightUnit | null } };

export type VariableDefinitionsFragment = VariableDefinitions_CustomUnitVariableDefinition_Fragment | VariableDefinitions_DistanceVariableDefinition_Fragment | VariableDefinitions_NumberVariableDefinition_Fragment | VariableDefinitions_StringVariableDefinition_Fragment | VariableDefinitions_WeightVariableDefinition_Fragment;

export const VariableDefinitionsFragmentDoc = gql`
    fragment VariableDefinitions on VariableDefinition {
  __typename
  ... on NumberVariableDefinition {
    key
    type
    min {
      ...CNumber
    }
    max {
      ...CNumber
    }
    defaultNumber {
      ...CNumber
    }
    userEditable
  }
  ... on WeightVariableDefinition {
    key
    type
    min {
      ...CNumber
    }
    max {
      ...CNumber
    }
    imperialWeightDefault {
      ...CWeight
    }
    metricWeightDefault {
      ...CWeight
    }
    validWeightUnits
    userEditable
  }
  ... on DistanceVariableDefinition {
    key
    type
    min {
      ...CNumber
    }
    max {
      ...CNumber
    }
    imperialDistanceDefault {
      ...CDistance
    }
    metricDistanceDefault {
      ...CDistance
    }
    validDistanceUnits
    userEditable
  }
  ... on CustomUnitVariableDefinition {
    key
    type
    min {
      ...CNumber
    }
    max {
      ...CNumber
    }
    defaultCustomUnitValue {
      ...CCustom
    }
    validCustomUnits
    userEditable
  }
  ... on StringVariableDefinition {
    key
    defaultString {
      ...CString
    }
    userEditable
  }
}
    ${CNumberFragmentDoc}
${CWeightFragmentDoc}
${CDistanceFragmentDoc}
${CCustomFragmentDoc}
${CStringFragmentDoc}`;