import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'date-fns';
import { leftPad, milliSecondsToDuration } from '@carabiner/common-utils';

const ONE_DAY_IN_MS = 432000000;
@Pipe({
  name: 'milliSecondsToStopwatchString',
})
export class MilliSecondsToStopwatchStringPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= ONE_DAY_IN_MS) {
      return '24+ hours';
    }
    return durationToStopWatchFormat(milliSecondsToDuration(value));
  }
}

function durationToStopWatchFormat(duration: Duration) {
  const leftPadZero = (v: number) => leftPad(v, 2, 0);
  return `${leftPadZero(duration.hours || 0)}:${leftPadZero(
    duration.minutes || 0
  )}:${leftPadZero(duration.seconds || 0)}`;
}
