import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'carabiner-alert-errors-block',
  templateUrl: './alert-errors-block.component.html',
  styleUrls: ['./alert-errors-block.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertErrorsBlockComponent {
  @Input() errors?: string[] = [];
}
