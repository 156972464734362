import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
export type LogicRuleFragment = { __typename: 'LogicRule', logic: object, type: Types.LogicRuleType };

export const LogicRuleFragmentDoc = gql`
    fragment LogicRule on LogicRule {
  __typename
  logic
  type
}
    `;