<carabiner-authentication-container
  [errors]="errors$ | async"
  [pending]="pending$ | async"
  title="Sign in"
>
  <form [formGroup]="form" (submit)="signIn()">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <button
      [disabled]="disableButton$ | async"
      mat-raised-button
      color="primary"
      type="submit"
    >
      Sign in
    </button>
  </form>
</carabiner-authentication-container>
