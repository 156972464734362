<carabiner-day-picker-form-control
  [id]="id"
  [disabledMessage]="to?.disabledMessage"
  [formlyAttributes]="field"
  [formControl]="formControl"
  [availableDays]="to?.availableDays"
  [maxDaySelections]="to.maxDaySelections">
</carabiner-day-picker-form-control>
<mat-error>
  <formly-validation-message [field]="field"></formly-validation-message>
</mat-error>
