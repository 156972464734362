import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationContainerComponent } from './authentication-container.component';
import {
  AlertErrorsBlockModule,
  ContainerModule,
} from '@carabiner/angular-shared/ui';
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
  declarations: [AuthenticationContainerComponent],
  imports: [
    CommonModule,
    AlertErrorsBlockModule,
    MatProgressBarModule,
    ContainerModule,
  ],
  exports: [AuthenticationContainerComponent],
})
export class AuthenticationContainerModule {}
