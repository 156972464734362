import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RequestMagicLinkTokenMutationVariables = Types.Exact<{
  input: Types.RequestMagicLinkToken;
}>;


export type RequestMagicLinkTokenMutation = { __typename?: 'Mutation', requestMagicLinkToken: boolean };

export const RequestMagicLinkTokenDocument = gql`
    mutation requestMagicLinkToken($input: RequestMagicLinkToken!) {
  requestMagicLinkToken(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestMagicLinkTokenGQL extends Apollo.Mutation<RequestMagicLinkTokenMutation, RequestMagicLinkTokenMutationVariables> {
    document = RequestMagicLinkTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }