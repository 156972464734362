import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: 'carabiner-start-time-end-time-group-type',
  templateUrl: './start-time-end-time-group-type.component.html',
  styleUrls: ['./start-time-end-time-group-type.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartTimeEndTimeGroupTypeComponent extends FieldType {

}
