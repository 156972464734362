import { Event, RepeatingEvent } from '@carabiner/angular-shared/data-access';
import {
  repeatingEventFormConfig,
  RepeatingEventFormModel,
  repeatingEventModelToEventWithOverride,
  repeatingEventToFormModelAndDetailBlock,
} from './repeating-event-utils';
import {
  relativeDistanceEventFormConfig,
  RelativeDistanceEventFormModel,
  relativeDistanceEventModelToEventWithOverride,
  relativeDistanceEventToFormModelAndDetailBlock,
} from './relative-distance-event-utils';
import {
  EventWithOverride,
  RelativeDistanceEventWithOverride,
  RepeatingEventWithOverride,
} from '../event-to-event-with-override';
import {
  isRelativeDistanceEventType,
  isRepeatingEventType,
} from '@carabiner/common-utils';
import { pathOr } from 'ramda';
import { OnDestroyService } from '@carabiner/angular-shared/ui';
import { ProgramStartAndEnd } from '../program-start-and-end';
import { FormSetupParams } from './core-types';

export type EventModel =
  | RepeatingEventFormModel
  | RelativeDistanceEventFormModel;

export function eventWithOverridesToFormModelAndDetailBlocks(
  ewo: EventWithOverride
): { details: string[]; model: EventModel } {
  if (isRepeatingEventType(ewo.event)) {
    return repeatingEventToFormModelAndDetailBlock(
      ewo as RepeatingEventWithOverride
    );
  }
  if (isRelativeDistanceEventType(ewo.event)) {
    return relativeDistanceEventToFormModelAndDetailBlock(
      ewo as RelativeDistanceEventWithOverride
    );
  }

  throw new Error(
    '[EventFormControlUtils] eventWithOverridesToFormModelAndDetailsBlock pass invalid event'
  );
}

export function setupEventForm({
  ewo,
  startAndEndDate$,
  destroy$,
  eventsFormArray,
  logger,
}: FormSetupParams) {
  if (isRepeatingEventType(ewo.event)) {
    return repeatingEventFormConfig({
      ewo,
      startAndEndDate$,
      destroy$,
      eventsFormArray,
      logger,
    } as any);
  }

  if (isRelativeDistanceEventType(ewo.event)) {
    return relativeDistanceEventFormConfig({
      ewo,
      startAndEndDate$,
      destroy$,
    } as any);
  }

  throw new Error(
    '[EventFormControlUtils] setup event form passed invalid event'
  );
}

export function formModelToEvent(ewoOriginal: EventWithOverride | null) {
  return function (model: any) {
    const modelAndEvent = { ewoOriginal, model };
    if (isRepeatingEventAndModel(ewoOriginal)) {
      return repeatingEventModelToEventWithOverride(
        modelAndEvent as {
          ewoOriginal: RepeatingEventWithOverride;
          model: RepeatingEventFormModel;
        }
      );
    }
    if (isRelativeDistanceEventAndModel(ewoOriginal)) {
      return relativeDistanceEventModelToEventWithOverride(
        modelAndEvent as {
          ewoOriginal: RelativeDistanceEventWithOverride;
          model: RelativeDistanceEventFormModel;
        }
      );
    }
    throw new Error(
      '[EventFormControlUtils] eventWithOverridesToFormModelAndDetailsBlock pass invalid event'
    );
  };
}

function isRepeatingEventAndModel(ewo: any): ewo is {
  originalEvent: Partial<RepeatingEvent>;
  model: RepeatingEventFormModel;
} {
  return isRepeatingEventType(ewo.event);
}

function isRelativeDistanceEventAndModel(ewo: any): ewo is {
  originalEvent: Partial<RepeatingEvent>;
  model: RepeatingEventFormModel;
} {
  return isRelativeDistanceEventType(ewo.event);
}

export function getTypeName(ewo: EventWithOverride | null): string {
  return pathOr('', ['event', '__typename'], ewo);
}
