import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MillisecondsToSecondsPipe } from './milliseconds-to-seconds.pipe';

@NgModule({
  declarations: [MillisecondsToSecondsPipe],
  exports: [MillisecondsToSecondsPipe],
  imports: [CommonModule],
})
export class MillisecondsToSecondsModule {}
