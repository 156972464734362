import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertErrorsBlockComponent } from './alert-errors-block.component';
import { AlertModule } from '../alert/alert.module';

@NgModule({
  declarations: [AlertErrorsBlockComponent],
  imports: [CommonModule, AlertModule],
  exports: [AlertErrorsBlockComponent],
})
export class AlertErrorsBlockModule {}
