import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlBlockComponent } from './html-block.component';

@NgModule({
  declarations: [
    HtmlBlockComponent
  ],
  exports: [
    HtmlBlockComponent
  ],
  imports: [
    CommonModule
  ]
})
export class HtmlBlockModule { }
