import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Self,
} from '@angular/core';
import { UiConfigService } from './ui-config.service';
import { OnDestroyService } from '@carabiner/angular-shared/ui';
import { takeUntil } from 'rxjs/operators';
import { PreviousRouteService } from '@carabiner/angular-shared/core-services';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'carabiner-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OnDestroyService],
})
export class AppComponent {
  title = 'frontend';

  // see styles.scss
  @HostBinding('class.dark-theme') darkTheme = false;
  @HostBinding('class.mat-app-background') matBackground = true;
  @HostBinding('class.mat-typography') typography = true;

  constructor(
    private uiConfigService: UiConfigService,
    @Self() readonly onDestroy: OnDestroyService,
    // dont remove this needs to be injected in the base component
    private previousRouteService: PreviousRouteService,
    private overlayContainer: OverlayContainer
  ) {
    const overlayClasses = this.overlayContainer.getContainerElement()
      .classList;
    overlayClasses.add('mat-typography');
    this.uiConfigService.darkMode$
      .pipe(takeUntil(this.onDestroy.triggered$))
      .subscribe((darkTheme) => {
        this.darkTheme = darkTheme;
        if (darkTheme) {
          overlayClasses.add('dark-theme');
        } else {
          overlayClasses.remove('dark-theme');
        }
      });
  }
}
