
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BaseContentBlock": [
      "ActionButtonContentBlock",
      "AudioContentBlock",
      "DataLogBlock",
      "HorizontalScrollContentBlock",
      "HtmlContentBlock",
      "ImageContentBlock",
      "OverlayContentBlock",
      "PlaylistContentBlock",
      "SimpleContentBlock",
      "TrackHeroTitleBlock"
    ],
    "BaseEvent": [
      "RelativeDistanceEvent",
      "RepeatingEvent"
    ],
    "BaseTrack": [
      "FinishedTrack",
      "RepeatedTrack",
      "TimedTrack"
    ],
    "CCustomUnit": [
      "CustomUnitConst",
      "CustomUnitRef"
    ],
    "CDistance": [
      "DistanceConst",
      "DistanceRef"
    ],
    "CNumber": [
      "NumberConst",
      "VariableRef"
    ],
    "CString": [
      "StringValue",
      "VariableRef"
    ],
    "CWeight": [
      "WeightConst",
      "WeightRef"
    ],
    "CoreLog": [
      "InitialiseLog",
      "SessionLog"
    ],
    "Event": [
      "RelativeDistanceEvent",
      "RepeatingEvent"
    ],
    "EventContent": [
      "ActionButtonContentBlock",
      "DataLogBlock",
      "HtmlContentBlock",
      "PlaylistContentBlock"
    ],
    "FormContentBlock": [
      "HtmlContentBlock",
      "OverlayContentBlock"
    ],
    "FormField": [
      "ContentBlockFormFieldDefinition",
      "InputFormFieldDefinition",
      "SliderFormFieldDefinition"
    ],
    "HorizontalScrollContent": [
      "ImageContentBlock"
    ],
    "NumberValue": [
      "CompletionValue",
      "CustomUnitConst",
      "DistanceConst",
      "NumberConst",
      "WeightConst"
    ],
    "OverlayContent": [
      "HtmlContentBlock"
    ],
    "PlaylistTrack": [
      "FinishedTrack",
      "RepeatedTrack",
      "TimedTrack"
    ],
    "PlaylistTrackContentBlock": [
      "ActionButtonContentBlock",
      "AudioContentBlock",
      "HtmlContentBlock",
      "SimpleContentBlock",
      "TrackHeroTitleBlock"
    ],
    "PreviewProgramContentBlock": [
      "ActionButtonContentBlock",
      "HorizontalScrollContentBlock",
      "HtmlContentBlock",
      "SimpleContentBlock"
    ],
    "ProgramSubscriptionLog": [
      "InitialiseLog",
      "SessionLog"
    ],
    "StateData": [
      "NumberState",
      "StringState"
    ],
    "VariableDefinition": [
      "CustomUnitVariableDefinition",
      "DistanceVariableDefinition",
      "NumberVariableDefinition",
      "StringVariableDefinition",
      "WeightVariableDefinition"
    ]
  }
};
      export default result;
    