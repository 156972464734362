import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { CurrentStateFragmentDoc } from '../program-subscription/current-state.fragment.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CurrentUserSubscriptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserSubscriptionsQuery = { __typename?: 'Query', currentUserSubscriptions: Array<{ __typename?: 'ProgramSubscription', id: string, program: { __typename?: 'Program', id: string, title: string }, currentUserState: { __typename?: 'CurrentUserState', system: { __typename?: 'SystemGeneratedVariables', startDate: string, endDate: string }, state: Array<{ __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null } | { __typename?: 'StringState', key: string, namespace: Types.VariableNamespace, namespaceId: string, stringValue: string }>, schedule: Array<{ __typename?: 'Appointment', date: string, eventId: string, startTime: string }> } }> };

export const CurrentUserSubscriptionsDocument = gql`
    query currentUserSubscriptions {
  currentUserSubscriptions {
    id
    program {
      id
      title
    }
    currentUserState {
      ...CurrentState
    }
  }
}
    ${CurrentStateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserSubscriptionsGQL extends Apollo.Query<CurrentUserSubscriptionsQuery, CurrentUserSubscriptionsQueryVariables> {
    document = CurrentUserSubscriptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }