import { Inject, Injectable, OnDestroy} from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, takeUntil } from "rxjs/operators";
import {
  EVENT_SESSION_INIT_TIME,
  LoggerService,
  LOGIC_RULE_RESULT,
  LogLevel,
  WINDOW
} from "@carabiner/angular-shared/core-services";
import { notNil } from "@carabiner/common-utils";
import { equals } from 'ramda';

export const DARK_MODE_KEY = 'darkMode'
export const LOG_LEVEL_KEY = 'logLevel'
export const ALLOWABLE_LOG_FILTER_KEYS_KEY = 'allowableLogFilterKeys'

interface UIConfig {
  [DARK_MODE_KEY]: boolean;
  [LOG_LEVEL_KEY]: LogLevel;
  [ALLOWABLE_LOG_FILTER_KEYS_KEY]: Set<string>
}

@Injectable({
  providedIn: 'root',
})
export class UiConfigService implements OnDestroy {
  readonly baseAllowableLogFilterKeyList = [LOGIC_RULE_RESULT, EVENT_SESSION_INIT_TIME]
  state = new BehaviorSubject({
    [DARK_MODE_KEY]: false,
    [LOG_LEVEL_KEY]: LogLevel.Warn,
    [ALLOWABLE_LOG_FILTER_KEYS_KEY]: [...this.baseAllowableLogFilterKeyList]
  });

  darkMode$: Observable<boolean> = this.state.pipe(
    extractDistinctValue(DARK_MODE_KEY)
  ) as Observable<boolean>;

  logLevel$: Observable<LogLevel> = this.state.pipe(
    extractDistinctValue(LOG_LEVEL_KEY)
  ) as Observable<LogLevel>

  allowableFilterKeys$ = this.state.pipe(
   extractDistinctValue(ALLOWABLE_LOG_FILTER_KEYS_KEY)
  ) as Observable<string[]>

  onDestroy$ = new Subject()

  constructor(
    @Inject(WINDOW) private window: Window,
    private logger: LoggerService
  ) {
    [DARK_MODE_KEY, LOG_LEVEL_KEY, ALLOWABLE_LOG_FILTER_KEYS_KEY].forEach((key) =>  this.init(key))
  }

  ngOnDestroy() {
    this.onDestroy$.next()
    this.onDestroy$.complete()
  }

  init(key) {
    this.initLocalStorage(key);
    this.listenForLocalStorageChanges(key);
    this.connectToLoggerService()
  }

  setDarkMode(value: boolean) {
    this.updateState(DARK_MODE_KEY, value)
  }

  setLogLevel(value: LogLevel)  {
    this.updateState(LOG_LEVEL_KEY, value)
  }

  addAllowableFilterKey(value: string) {
    const currentValues = this.state.getValue()[ALLOWABLE_LOG_FILTER_KEYS_KEY]
    const newSet = new Set(currentValues)
    newSet.add(value)
    this.updateState(ALLOWABLE_LOG_FILTER_KEYS_KEY, Array.from(newSet))
  }

  removeAllowableFilterKey(value: string) {
    const currentValues = this.state.getValue()[ALLOWABLE_LOG_FILTER_KEYS_KEY]
    const newSet = new Set(currentValues)
    newSet.delete(value)
    this.updateState(ALLOWABLE_LOG_FILTER_KEYS_KEY, Array.from(newSet))
  }

  setValue(key, value) {
    this.updateState(key, value);
  }

  private initLocalStorage(key) {
    const value = this.storageGet(key)
    if (notNil(value)) {
      this.updateState(key, value)
      return;
    }

    if (key === DARK_MODE_KEY) {
      const darkModeWindowValue = this.window?.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      if (darkModeWindowValue !== undefined) {
        this.nextState(DARK_MODE_KEY, darkModeWindowValue );
        return;
      }
    }
  }

  private listenForLocalStorageChanges(key) {
    fromEvent(this.window, 'storage')
      .pipe(
        filter((v: StorageEvent) => v.key === key),
        filter((v: StorageEvent) => v.newValue !== v.oldValue),
        map((v) => JSON.parse(v.newValue)),
        takeUntil(this.onDestroy$)
      )
      .subscribe((v) => this.nextState(key, v));
  }

  private updateState(key: keyof UIConfig, value) {
    this.nextState(key, value)
    this.storageSet(key, value);
  }

  private nextState(key, value) {
    const current = this.state.getValue();
    const currentValue = current[key]
    if (currentValue === value) {
      return
    }
    const update = { [key]: value };
    this.state.next({ ...current, ...update });
  }

  storageGet(key) {
    const value = this.window.localStorage.getItem(key)
    if (notNil(value)) {
      return JSON.parse(value)
    }
    return null
  }

  storageSet(key, value) {
    const currentValue = this.storageGet(key)
    if (equals(currentValue, value)) {
      return;
    }
    this.window.localStorage.setItem(key, JSON.stringify(value))
  }

  private connectToLoggerService() {
    const setLogLevel$ = this.logLevel$
      .pipe(
        map((v) => this.logger.setLogLevel(v)
      ))
    const setAllowableFilterKeys$ = this.allowableFilterKeys$
      .pipe(
        map((v: string[]) =>
          this.logger.setAllowableFilterKeys(v)
        ))

    merge(setLogLevel$, setAllowableFilterKeys$)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe()
  }
}

function extractValue<T, K extends keyof T>(key: K) {
  return (object: T) => object[key];
}

function extractDistinctValue<T>(key: keyof T) {
  return (source: Observable<T>) =>
    source.pipe(map(extractValue(key)), distinctUntilChanged());
}
