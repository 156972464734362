import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MilliSecondsToDurationStringPipe } from './milli-seconds-to-duration-string.pipe';

@NgModule({
  declarations: [MilliSecondsToDurationStringPipe],
  exports: [MilliSecondsToDurationStringPipe],
  imports: [CommonModule],
})
export class MilliSecondsToDurationStringModule {}
