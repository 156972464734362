import { ConfigOption } from "@ngx-formly/core";
import { FlexGroupTypeComponent } from "./flex-group.type.component";

export const FlexGroupFormlyTypeConfig: ConfigOption = {
  types: [
    {
      name: "flex-group",
      component: FlexGroupTypeComponent
    }
  ]
};
