import { RelativeTo } from '@carabiner/models';

interface GetStartAndEndDateParams {
  previousEventEnd?: Date | string | undefined;
  programStartDate: string;
  relativeTo: RelativeTo;
}

export function calculateStartDate({
  previousEventEnd,
  programStartDate,
  relativeTo,
}: GetStartAndEndDateParams): Date | string {
  if (!previousEventEnd) {
    return programStartDate;
  }

  if (relativeTo === RelativeTo.PreviousEventOrProgramStart) {
    return previousEventEnd;
  }

  return programStartDate;
}
