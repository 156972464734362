import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { RRuleWeekday } from '@carabiner/angular-shared/data-access';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { head, isEmpty } from 'ramda';
import { map, takeUntil } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';

interface Day {
  displayDay: string;
  fullDayName: string;
  value: RRuleWeekday;
}

interface PresentationDay extends Day {
  selected: boolean;
  unavailable: boolean;
}

const extractValue = (day: Day) => day.value;

const baseDays = (): Day[] => [
  {
    displayDay: 'M',
    fullDayName: 'Monday',
    value: RRuleWeekday.MO,
  },
  {
    displayDay: 'T',
    fullDayName: 'Tuesday',
    value: RRuleWeekday.TU,
  },
  {
    displayDay: 'W',
    fullDayName: 'Wednesday',
    value: RRuleWeekday.WE,
  },
  {
    displayDay: 'T',
    fullDayName: 'Thursday',
    value: RRuleWeekday.TH,
  },
  {
    displayDay: 'F',
    fullDayName: 'Friday',
    value: RRuleWeekday.FR,
  },
  {
    displayDay: 'S',
    fullDayName: 'Saturday',
    value: RRuleWeekday.SA,
  },
  {
    displayDay: 'S',
    fullDayName: 'Sunday',
    value: RRuleWeekday.SU,
  },
];

const allDays = baseDays().map(extractValue);

@Component({
  selector: 'carabiner-day-picker-form-control',
  templateUrl: './day-picker-form-control.component.html',
  styleUrls: ['./day-picker-form-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DayPickerFormControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DayPickerFormControlComponent),
      multi: true,
    },
  ],
})
export class DayPickerFormControlComponent
  implements ControlValueAccessor, Validator, OnDestroy
{
  destroy$ = new Subject();
  newAvailableDays$ = new Subject();

  baseDays$: BehaviorSubject<Day[]> = new BehaviorSubject(baseDays());
  selectedDays$: BehaviorSubject<RRuleWeekday[]> = new BehaviorSubject<
    RRuleWeekday[]
  >([]);
  availableDays$: BehaviorSubject<RRuleWeekday[]> = new BehaviorSubject<
    RRuleWeekday[]
  >(allDays);

  days$: Observable<PresentationDay[]> = combineLatest([
    this.baseDays$,
    this.selectedDays$,
    this.availableDays$,
  ]).pipe(
    map(([baseDays, selectedDays, availableDays]) => {
      const selectedSet = new Set(selectedDays);
      const availableSet = new Set(availableDays);
      return baseDays.map((day) => ({
        ...day,
        selected: selectedSet.has(day.value),
        unavailable: !availableSet.has(day.value),
      }));
    })
  );

  onChanged!: (v: RRuleWeekday[]) => void;
  onTouched!: () => void;
  disabled = false;

  _maxDaySelections = 7;
  @Input()
  set maxDaySelections(value: number | undefined) {
    if (typeof value === 'number') {
      this._maxDaySelections = value;
    } else {
      this._maxDaySelections = 7;
    }
  }

  @Input()
  disabledMessage?: string;

  @Input()
  set availableDays(
    availableDays: RRuleWeekday[] | Observable<RRuleWeekday[]> | undefined
  ) {
    if (!availableDays) {
      return;
    }
    if (availableDays instanceof Observable) {
      this.newAvailableDays$.next();
      availableDays
        .pipe(takeUntil(this.newAvailableDays$), takeUntil(this.destroy$))
        .subscribe((v) => this.updateAvailableDays(v));
    } else {
      this.updateAvailableDays(availableDays);
    }
  }

  @Input()
  formlyAttributes?: FormlyFieldConfig;

  formControl(): AbstractControl | undefined {
    return this?.formlyAttributes?.formControl;
  }

  isTouched() {
    // if using with non formly control try this
    // https://stackoverflow.com/questions/44731894/get-access-to-formcontrol-from-the-custom-form-component-in-angular
    return this.formControl()?.touched;
  }

  updateAvailableDays(availableDays: RRuleWeekday[]) {
    const currentAvailableDays = this.availableDays$.getValue();
    if (availableDaysMatch(availableDays, currentAvailableDays)) {
      return;
    }
    this.availableDays$.next(availableDays);
    this.triggerChange(); // so validations get processed
  }

  writeValue(value: RRuleWeekday[] | null) {
    if (value) {
      this.selectedDays$.next(value);
    } else {
      this.selectedDays$.next([]);
    }
  }

  registerOnChange(fn: (v: RRuleWeekday[]) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  singleDaySelection(): boolean {
    return this._maxDaySelections === 1;
  }

  toggleDay(day: RRuleWeekday) {
    if (this.disabled) {
      return;
    }

    const selectedDays = toggleDay(
      day,
      this.selectedDays$.getValue(),
      this.singleDaySelection()
    );

    this.selectedDays$.next(Array.from(selectedDays));
    this.onTouched();
    this.triggerChange();
  }

  triggerChange() {
    if (this.onChanged) {
      this.onChanged(this.selectedDays$.getValue());
    }
  }

  validate(): ValidationErrors | null {
    const selectedDays = this.selectedDays$.getValue().length;
    const maxDaySelectionExceeded = selectedDays > this._maxDaySelections;

    const unavailableDaySelected = selectedDaysDoNotMatchAvailableDays(
      this.selectedDays$.getValue(),
      this.availableDays$.getValue()
    );

    const errors = {
      ...(maxDaySelectionExceeded ? { maxDaySelectionExceeded } : null),
      ...(unavailableDaySelected ? { unavailableDaySelected } : null),
    };

    if (unavailableDaySelected) {
      setTimeout(() => this.setFirstAvailableDayIfUntouched(), 0);
    }

    if (isEmpty(errors)) {
      return null;
    }
    return errors;
  }

  setFirstAvailableDayIfUntouched() {
    const dayToSet = head(this.availableDays$.getValue());
    if (!this.isTouched() && dayToSet && this.singleDaySelection()) {
      this.selectedDays$.next([dayToSet]);
      this.triggerChange();
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

function toggleDay(
  day: RRuleWeekday,
  selectedDays: RRuleWeekday[],
  singleDaySelection: boolean
) {
  const selectedSet = new Set(selectedDays);
  if (singleDaySelection) {
    if (selectedSet.has(day)) {
      return [];
    }
    return [day];
  }

  if (selectedSet.has(day)) {
    selectedSet.delete(day);
  } else {
    selectedSet.add(day);
  }
  return Array.from(selectedSet);
}

export function availableDaysMatch(
  newDays: RRuleWeekday[],
  currentDays: RRuleWeekday[]
) {
  if (newDays.length !== currentDays.length) {
    return false;
  }
  return isSuperset(new Set(newDays), new Set(currentDays));
}

export function selectedDaysDoNotMatchAvailableDays(
  selectedDays: RRuleWeekday[],
  availableDays: RRuleWeekday[]
) {
  return !isSuperset(new Set(availableDays), new Set(selectedDays));
}

function isSuperset(set: Set<RRuleWeekday>, subSet: Set<RRuleWeekday>) {
  for (const elem of subSet) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}
