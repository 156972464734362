import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  OnDestroy,
} from '@angular/core';
import { createTimeOptions, TimeOptions } from './time-options';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface TimePickerTemplateOptions {
  label: string;
}

@Component({
  selector: 'carabiner-time-picker-control',
  templateUrl: './time-picker-control.component.html',
  styleUrls: ['./time-picker-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerControlComponent),
      multi: true,
    },
  ],
})
export class TimePickerControlComponent
  implements ControlValueAccessor, OnDestroy
{
  control = new UntypedFormControl();
  options: TimeOptions[] = createTimeOptions(15);
  onDestroy$ = new Subject();

  @Input() templateOptions?: TimePickerTemplateOptions;

  writeValue(val: string | null) {
    if (!val) {
      return;
    }
    this.control.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: () => void) {
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.control.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => fn());
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable({ onlySelf: true, emitEvent: false });
      return;
    }
    this.control.enable({ onlySelf: true, emitEvent: false });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
