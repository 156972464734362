import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexGroupTypeComponent } from "./flex-group-type/flex-group.type.component";
import { FormlyModule } from "@ngx-formly/core";
import { FlexGroupFormlyTypeConfig } from "./flex-group-type/flex-group.type.config";

@NgModule({
  declarations: [
    FlexGroupTypeComponent
  ],
  imports: [
    CommonModule,
    FormlyModule.forChild(FlexGroupFormlyTypeConfig)
  ]
})
export class FlexGroupModule { }
