import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
export type NumberStateFragment = { __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null };

export type StringStateFragment = { __typename?: 'StringState', key: string, namespace: Types.VariableNamespace, namespaceId: string, stringValue: string };

export type StateData_NumberState_Fragment = { __typename?: 'NumberState', key: string, namespace: Types.VariableNamespace, namespaceId: string, numberValue: number, unit?: string | null };

export type StateData_StringState_Fragment = { __typename?: 'StringState', key: string, namespace: Types.VariableNamespace, namespaceId: string, stringValue: string };

export type StateDataFragment = StateData_NumberState_Fragment | StateData_StringState_Fragment;

export const NumberStateFragmentDoc = gql`
    fragment NumberState on NumberState {
  key
  namespace
  namespaceId
  numberValue
  unit
}
    `;
export const StringStateFragmentDoc = gql`
    fragment StringState on StringState {
  key
  namespace
  namespaceId
  stringValue
}
    `;
export const StateDataFragmentDoc = gql`
    fragment StateData on StateData {
  ... on NumberState {
    ...NumberState
  }
  ... on StringState {
    ...StringState
  }
}
    ${NumberStateFragmentDoc}
${StringStateFragmentDoc}`;