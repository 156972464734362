import { Duration, intervalToDuration } from "date-fns";

export function milliSecondsToDuration(milliSeconds: number): Duration {
  const epoch = new Date(0)
  const secondsAfterEpoch = new Date(milliSeconds)
  try {
    return intervalToDuration({
      start: epoch,
      end: secondsAfterEpoch
    })
  } catch (e) {
    throw new Error(`Could not create duration from milliseconds`)
  }
}

