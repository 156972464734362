import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationToEndTimeTypeComponent } from './duration-to-end-time/duration-to-end-time.type.component';
import { FormlyModule } from "@ngx-formly/core";
import { DurationToEndTimeConfig } from "./duration-to-end-time/duration-to-end-time.type.config";

@NgModule({
  declarations: [DurationToEndTimeTypeComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild(DurationToEndTimeConfig)
  ]
})
export class DurationToEndTimeModule { }
