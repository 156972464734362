import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProgramEventsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProgramEventsQuery = { __typename?: 'Query', program: { __typename?: 'Program', id: string, title: string, description: string, categoryIds: Array<string>, events: Array<{ __typename?: 'RelativeDistanceEvent', id: string, description: string, title: string } | { __typename?: 'RepeatingEvent', id: string, description: string, title: string, repeating: { __typename?: 'RepeatConfig', byWeekDay?: Array<Types.RRuleWeekday> | null }, content: Array<{ __typename?: 'ActionButtonContentBlock' } | { __typename?: 'DataLogBlock' } | { __typename?: 'HtmlContentBlock' } | { __typename?: 'PlaylistContentBlock', tracks: Array<{ __typename?: 'FinishedTrack', title: string } | { __typename?: 'RepeatedTrack', title: string } | { __typename?: 'TimedTrack', title: string }> }> }> } };

export const ProgramEventsDocument = gql`
    query programEvents($id: ID!) {
  program(id: $id) {
    id
    title
    description
    categoryIds
    events {
      ... on BaseEvent {
        id
        description
        title
      }
      ... on RepeatingEvent {
        repeating {
          byWeekDay
        }
        content {
          ... on PlaylistContentBlock {
            tracks {
              ... on BaseTrack {
                title
              }
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProgramEventsGQL extends Apollo.Query<ProgramEventsQuery, ProgramEventsQueryVariables> {
    document = ProgramEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }