import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventFormlyTypeComponent } from "./event-formly-type/event.type.component";
import { FormlyModule } from "@ngx-formly/core";
import { EventFormControlComponent } from './event-form-control/event-form-control.component';
import { ReactiveFormsModule } from "@angular/forms";
import { DayPickerModule } from "../day-picker/day-picker.module";
import { TimePickerModule } from "../time-picker/time-picker.module";
import { DurationToEndTimeModule } from "../duration-to-end-time/duration-to-end-time.module";
import { FlexGroupModule } from '../flex-group/flex-group.module';

@NgModule({
  declarations: [EventFormlyTypeComponent, EventFormControlComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DayPickerModule,
    TimePickerModule,
    DurationToEndTimeModule,
    FlexGroupModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'event',
          component: EventFormlyTypeComponent,
        }
      ]
    })
  ]
})
export class EventFormlyModule { }
