import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SignInMutationVariables = Types.Exact<{
  input: Types.SignIn;
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn: { __typename?: 'SignInResponse', success: boolean, token?: string | null, subscriptionsRedirect?: { __typename?: 'SubscriptionsRedirect', firstSubscriptionId?: string | null, multipleSubscriptions: boolean } | null } };

export const SignInDocument = gql`
    mutation signIn($input: SignIn!) {
  signIn(input: $input) {
    success
    token
    subscriptionsRedirect {
      firstSubscriptionId
      multipleSubscriptions
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignInGQL extends Apollo.Mutation<SignInMutation, SignInMutationVariables> {
    document = SignInDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }