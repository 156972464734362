import { ProjectHeroHeadingComponent } from "./project-hero-heading.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [ProjectHeroHeadingComponent],
  exports: [ProjectHeroHeadingComponent]
})
export class ProjectHeroHeadingModule {}
