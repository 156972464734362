import * as Types from '../../generated/types';

import { gql } from 'apollo-angular';
export type FormFields_ContentBlockFormFieldDefinition_Fragment = { __typename: 'ContentBlockFormFieldDefinition', type: Types.FormFieldType, templateOptions: { __typename?: 'ContentBlockTemplateOptions', content: Array<{ __typename: 'HtmlContentBlock', type: Types.ContentBlockType, html: string, styles?: string | null } | { __typename: 'OverlayContentBlock', type: Types.ContentBlockType, linkDescription: string, content: Array<{ __typename?: 'HtmlContentBlock', html: string }> }> } };

export type FormFields_InputFormFieldDefinition_Fragment = { __typename: 'InputFormFieldDefinition', key: string, type: Types.FormFieldType, templateOptions: { __typename?: 'InputFormFieldTemplateOptions', description?: string | null, label: string, placeholder?: string | null, min?: number | null, max?: number | null, type?: Types.InputFieldType | null, required?: boolean | null } };

export type FormFields_SliderFormFieldDefinition_Fragment = { __typename: 'SliderFormFieldDefinition', key: string, type: Types.FormFieldType, templateOptions: { __typename?: 'SliderFormTemplateOptions', description?: string | null, label: string, placeholder?: string | null, min?: number | null, max?: number | null, step: number, required?: boolean | null } };

export type FormFieldsFragment = FormFields_ContentBlockFormFieldDefinition_Fragment | FormFields_InputFormFieldDefinition_Fragment | FormFields_SliderFormFieldDefinition_Fragment;

export const FormFieldsFragmentDoc = gql`
    fragment FormFields on FormField {
  __typename
  ... on InputFormFieldDefinition {
    __typename
    key
    type
    templateOptions {
      description
      label
      placeholder
      min
      max
      type
      required
    }
  }
  ... on SliderFormFieldDefinition {
    __typename
    key
    type
    templateOptions {
      description
      label
      placeholder
      min
      max
      step
      required
    }
  }
  ... on ContentBlockFormFieldDefinition {
    __typename
    type
    templateOptions {
      content {
        ... on HtmlContentBlock {
          __typename
          type
          html
          styles
        }
        ... on OverlayContentBlock {
          __typename
          type
          linkDescription
          content {
            ... on HtmlContentBlock {
              html
            }
          }
        }
      }
    }
  }
}
    `;