import { ConfigOption } from "@ngx-formly/core";
import { TimePickerFormlyTypeComponent } from "./time-picker.type.component";

export const TimePickerFormlyTypeConfig: ConfigOption = {
  types: [
    {
      name: "time-picker",
      component: TimePickerFormlyTypeComponent
    }
  ]
};
