import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TrackPreviewComponent } from './track-preview.component';
import { MilliSecondsToDurationStringModule } from '@carabiner/angular-shared/pipes';

@NgModule({
  imports: [CommonModule, RouterModule, MilliSecondsToDurationStringModule],
  declarations: [TrackPreviewComponent],
  exports: [TrackPreviewComponent],
})
export class TrackPreviewModule {}
