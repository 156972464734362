import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StartHeadingComponent } from "./start-heading.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [StartHeadingComponent],
  exports: [StartHeadingComponent]
})
export class StartHeadingModule {}
