import { InjectionToken, Provider } from '@angular/core';

export const WINDOW = new InjectionToken('WINDOW');
export const WINDOW_PROVIDER: Provider = {
  provide: WINDOW,
  useValue: window,
};

export const CONSOLE = new InjectionToken('CONSOLE');
export const CONSOLE_PROVIDER: Provider = {
  provide: CONSOLE,
  useValue: console,
};

export const INTL = new InjectionToken('INTL')
export const INTL_PROVIDER: Provider = {
  provide: INTL,
  useValue: Intl 
}