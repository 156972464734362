export enum COMMON_STATUS {
  'idle' = 'idle',
  'pending' = 'pending',
  'resolved' = 'resolved',
  'rejected' = 'rejected',
}

export function isIdle(status: COMMON_STATUS): boolean {
  return status === COMMON_STATUS.idle;
}

export function isPending(status: COMMON_STATUS): boolean {
  return status === COMMON_STATUS.pending;
}

export function isResolved(status: COMMON_STATUS): boolean {
  return status == COMMON_STATUS.resolved;
}

export function isRejected(status: COMMON_STATUS): boolean {
  return status === COMMON_STATUS.rejected;
}

export function invalidOrPending([signInStatus, formStatus]: [
  COMMON_STATUS,
  string
]) {
  if (formStatus === 'INVALID') {
    return true;
  }
  if (signInStatus === COMMON_STATUS.pending) {
    return true;
  }
  return false;
}
