import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HorizontalScrollComponent } from "./horizontal-scroll.component";

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [HorizontalScrollComponent],
  exports: [HorizontalScrollComponent]
})
export class HorizontalScrollModule {}
