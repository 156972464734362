import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, HostBinding } from "@angular/core";

@Component({
  selector: 'carabiner-full-screen-loading-overlay',
  templateUrl: './full-screen-loading-overlay.component.html',
  styleUrls: ['./full-screen-loading-overlay.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullScreenLoadingOverlayComponent implements OnInit {

  @HostBinding('class.mat-typography') typography = true;

  messages = ['']
  constructor() { }

  ngOnInit(): void {
  }

}
