import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { ProgramCategoryHeadingComponent } from "./program-category-heading.component";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [ProgramCategoryHeadingComponent],
  exports: [ProgramCategoryHeadingComponent]
})
export class ProgramCategoryHeadingModule {}
