import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRouting } from './app.routing';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// weirdly typings only work when we import the component into this module
import { AddToGoogleCalendarComponent } from './programs/add-to-google-calendar/add-to-google-calendar.component';
import { GapiAuthService } from './programs/gcal-service/gapi-auth.service';
import { FullScreenLoadingOverlayModule } from '@carabiner/angular-shared/ui';
import {
  JWT_DECODE_PROVIDER,
  TokenInterceptor,
} from '@carabiner/angular-shared/authentication';
import { GraphQLModule } from '@carabiner/angular-shared/data-access';
import {
  WINDOW_PROVIDER,
  CONSOLE_PROVIDER,
  INTL_PROVIDER,
} from '@carabiner/angular-shared/core-services';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormlyRootModule } from '@carabiner/angular-shared/formly';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormlyRootModule,
    HttpClientModule,
    GraphQLModule,
    AppRouting,
    FullScreenLoadingOverlayModule,
    OverlayModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: navigator?.language || 'en-US' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    WINDOW_PROVIDER,
    CONSOLE_PROVIDER,
    INTL_PROVIDER,
    JWT_DECODE_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
