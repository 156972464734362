import { MatNativeDateModule } from "@angular/material/core";
import { FormlyMatDatepickerModule } from "@ngx-formly/material/datepicker";
import { NgModule } from "@angular/core";


const IMPORT_EXPORT = [
  MatNativeDateModule,
  FormlyMatDatepickerModule
]

@NgModule({
  imports: IMPORT_EXPORT,
  exports: IMPORT_EXPORT
})
export class FormlyDatepickerBundle {}
